export function translate(text) {
    return text;
}

export function totalMenuPrice(basePrice, qty, extras) {
    let addOns = 0;
    for (const extra of extras) {
        if(extra.type === 'combo'){
            const keys = Object.keys(extra.items);
            keys.forEach(key => {
                extra.items[key].forEach(item => {
                    addOns += parseFloat(item.price);
                });
            });
        } else {
            extra.items.forEach(item => {
                addOns += parseFloat(item.price);
            });
        }

    }
    return (basePrice * qty) + addOns;
}

export function areOptionsEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }
  
    for (let i = 0; i < arr1.length; i++) {
        if(arr1[i]['option_id'] !== arr2[i]['option_id']) {
            return false;
        }
        if(arr1[i]['type'] !== arr2[i]['type']) {
            return false;
        }
        if(arr1[i]['type'] === 'combo') {
            Object.keys(arr1[i]['items']).forEach(key => {
                if (arr1[i]['items'][key].length !== arr2[i]['items'][key].length) {
                    return false;
                }

                arr1[i]['items'][key].forEach(itemDetail => {
                    for (let subKey in itemDetail) {
                        if (arr1[i]['items'][key][subKey] !== arr2[i]['items'][key][subKey]) {
                            return false;
                        }
                    }
                })
                
            });
        } else {
            arr1[i]['items'].forEach(itemDetail => {
                for (let key in itemDetail) {
                    if (arr1[i]['items'][key] !== arr2[i]['items'][key]) {
                        return false;
                    }
                }
            })
        }
    
    }
  
    return true;
  }
