import React, { useEffect, useState,useRef } from 'react';
import { StyleSheet, ScrollView, Switch, Alert } from 'react-native';
import { useNavigate } from "react-router-dom";
import apiClient from '../webservice/Client';
import storage from '../storage/Storage';
import { Radio, Center, NativeBaseProvider, Text, HStack, Button, Box, Heading } from "native-base";
import '../../styles/styles.css';
import MainContainer from '../layouts/Container';
import { translate } from '../helpers';
import { connect, useDispatch } from 'react-redux';
import ordersActions from '../store/orders/actions';
import restaurantActions from '../store/restaurant/actions';

function PaymentMethod(props) {
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const [value, setValue] = useState("");

    let ordertype = props.orders.orderConfirmationDetails.order_type;
    
    let paymentSettings = null;
    switch(ordertype) {
        case 'dine_in':
            paymentSettings = props.restaurant.settings.payment_methods;
            break;
        case 'takeaway':
            paymentSettings = props.restaurant.settings.takeaway_payment_methods;
            break;
        case 'pickup':
            paymentSettings = props.restaurant.settings.pickup_payment_methods;
            break;
        case 'delivery':
            paymentSettings = props.restaurant.settings.delivery_payment_methods;
            break;
        default:
            break;
    }

    const [settings, setSettings] = useState(paymentSettings);

    useEffect(() => {
        dispatch(restaurantActions.getSettings())
    }, []);

    const goPay = async () => {
        console.log("value selected payment method", value);
        if(value == ""){
            alert('Please select a payment method.');
            
        }else{
            
            if(value == 'till'){
                storage.setPaymentMethod(value);
    
                let payload = props.orders.orderConfirmationDetails;
                payload.payment_type = 'till';
                payload.onFinished = () => navigate("/thankyou");
    
                dispatch(ordersActions.sendOrderDetails(payload));
            }else if(value == 'card'){
                storage.setPaymentMethod(value);
                navigate("/checkout");
            }else{
                //setup google pay/applepay
                storage.setPaymentMethod(value);
                navigate("/checkout");
            }
        }

    };

    const onCancel = () => {
        navigate("/")
    };

    // const {payment_methods, takeaway_payment_methods} = props.restaurant.settings;

    return (
        <MainContainer
            title='Back'
            isHome={false}
            hideMenu={true}
            hideLogo={false}
            imagePath={null}>
            <Center>
                <Box bg="#FFFFFF" padding={4} height='100%' width='98%' borderRadius={10}>
                    <Box mb={10} height="5%">
                        <Heading style={styles.content} fontWeight="bold"> {translate('Payment Method')} </Heading>
                    </Box>
                    <Radio.Group defaultValue="card" name="myRadioGroup" accessibilityLabel="Pay by"
                        value={value} onChange={nextValue => {
                            setValue(nextValue);
                        }}>
                        {(settings.pay_in_till) && 
                            <Radio value="till" colorScheme="brown" size="sm" my={1}>
                                <Text style={styles.title}>Pay by till</Text>
                            </Radio>
                        }

                        {settings.card && (
                            <Radio value="card" colorScheme="white" size="sm" my={1} >
                                <Text style={styles.title}>Online Payment</Text>
                            </Radio>
                        )}

                        {/* {payment_methods.google_pay || payment_methods.apple_pay && (
                            <Radio value="pay" colorScheme="white" size="sm" my={1} >
                                <Text style={styles.title}>Pay by Applepay/Googlepay</Text>
                            </Radio>
                        )} */}
                    </Radio.Group>
                    <HStack paddingTop={10} alignItems="center" justifyContent="space-evenly" my={2}>
                    {props.orders.loading == true && 
                        <Button disabled={true} borderRadius="20" height="8" width="50%" top={0} bg="#4d3728" onPress={goPay} colorScheme="transparent">
                            <Text style={styles.order}> {translate('Sending...')}</Text>
                        </Button>
                    }
                    {props.orders.loading == false && 
                        <Button disabled={false} borderRadius="20" height="8" width="50%" top={0} bg="#4d3728" onPress={goPay} colorScheme="transparent">
                            <Text style={styles.order}> {translate('Pay')}</Text>
                        </Button>
                    }
                        <Button borderRadius="20" height="8" width="50%" top={0} bg="light.100" onPress={onCancel} colorScheme="transparent">
                            <Text style={styles.title}>{translate('Later')}</Text>
                        </Button>
                    </HStack>
                </Box>
            </Center>
        </MainContainer>
    )
}

const styles = StyleSheet.create({
    menuContainer: {
        marginBottom: 100
    },
    footer: {
        backgroundColor: '#2b3036',
        width: '100%',
        fontStyle: "Helvetica-NeueLT",
        fontSize: 10,
        fontWeight: "normal",
        color: "#f5f5f4"
    },
    gridContainer: {
        backgroundColor: '#46362a',
        width: '100%',
    },
    heading: {
        fontStyle: "Helvetica-NeueLT",
        fontSize: 25,
        fontWeight: "normal",
        color: "#f5f5f4",
        width: 230
    },
    title: {
        fontStyle: "Helvetica-NeueLT",
        fontSize: 15,
        fontWeight: "normal",
        color: "#4d3728",
        mt: 17,
    },
    mainContainer: {
        height: 156,
    },
    content: {
        fontStyle: "Helvetica-NeueLT",
        fontSize: 25,
        fontWeight: "normal",
        color: "#4d3728",
        width: '100%',
    },
    tableNumber: {
        fontStyle: "Helvetica-NeueLT",
        fontSize: 25,
        fontWeight: "normal",
        color: "#4d3728",
        width: 195,
        position: "absolute",
        top: -35,
        right: 0,
    },
    order: {
        color: '#ffffff',
        fontStyle: "Helvetica-NeueLT",
        fontSize: 15,
        fontWeight: "normal",
    },
    input: {
        height: 40,
        margin: 12,
        borderWidth: 1,
        padding: 10,
    },
    img: {
        paddingLeft: 10,
    },
    cursor: 'pointer',
});

function mapStateToProps(state) {
    return {
        orders: state.orders,
        payments: state.payments,
        restaurant: state.restaurant
    }
  }
  
export default connect(mapStateToProps)(PaymentMethod);