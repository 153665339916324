export default {
    RESTAURANT_SET_LOADING: 'RESTAURANT_SET_LOADING',
    RESTAURANT_GET_DETAILS: 'RESTAURANT_GET_DETAILS',
    RESTAURANT_SET_DETAILS: 'RESTAURANT_SET_DETAILS',
    RESTAURANT_GET_SETTINGS: 'RESTAURANT_GET_SETTINGS',
    RESTAURANT_SET_SETTINGS: 'RESTAURANT_SET_SETTINGS',

    CATEG_SET_LOADING: 'CATEGORIES_SET_LOADING',
    CATEG_GET_CATEGORIES: 'CATEG_GET_CATEGORIES',
    CATEG_SET_CATEGORIES: 'CATEG_SET_CATEGORIES',
    CATEG_GET_CATEGORY_MENUS: 'CATEG_GET_CATEGORY_MENUS',
    CATEG_SET_CATEGORY_MENUS: 'CATEG_SET_CATEGORY_MENUS',

    MENU_SET_LOADING: 'MENU_SET_LOADING',
    MENU_GET_MENUS: 'MENU_GET_MENUS',
    MENU_SET_MENUS: 'MENU_SET_MENUS',
    MENU_GET_UNCATEGORIZED_MENUS: 'MENU_GET_UNCATEGORIZED_MENUS',
    MENU_SET_UNCATEGORIZED_MENUS: 'MENU_SET_UNCATEGORIZED_MENUS',

    CART_ADD_TO_CART: 'CART_ADD_TO_CART',
    CART_UPDATE_CART: 'CART_UPDATE_CART',
    CART_CLEAR_CART: 'CART_CLEAR_CART',
    CART_SET_LOADING: 'CART_SET_LOADING',
    CART_GET_DETAILS: 'CART_GET_DETAILS',
    CART_SET_DETAILS: 'CART_SET_DETAILS',

    ORDER_SET_LOADING: 'ORDER_SET_LOADING',
    ORDER_SEND_ORDER_DETAILS: 'ORDER_SEND_ORDER_DETAILS',
    ORDER_GET_ORDER_DETAILS: 'ORDER_GET_ORDER_DETAILS',
    ORDER_SET_ORDER_DETAILS: 'ORDER_SET_ORDER_DETAILS',
    ORDER_GET_ORDERCONIFRMATION_DETAILS: 'ORDER_GET_ORDERCONIFRMATION_DETAILS',
    ORDER_SET_ORDERCONIFRMATION_DETAILS: 'ORDER_SET_ORDERCONIFRMATION_DETAILS',
    ORDER_UPDATE_STATUS: 'ORDER_UPDATE_STATUS',

    PAYMENT_SET_LOADING: 'PAYMENT_SET_LOADING',
    PAYMENT_GET_DETAILS: 'PAYMENT_GET_DETAILS',
    PAYMENT_SET_DETAILS: 'PAYMENT_SET_DETAILS',
    PAYMENT_PAY_BY_TILL: 'PAYMENT_PAY_BY_TILL',
    PAYMENT_SUCCESS: 'PAYMENT_SUCCESS',
}