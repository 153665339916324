import React, {useEffect, useState, useMemo} from 'react';
import {Dimensions, StyleSheet, View} from 'react-native';
import { HStack, VStack, ZStack, Pressable, Box, Text, Button, Image, Center, Icon, Circle} from 'native-base';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigate } from "react-router-dom";
import { useSpring, animated } from '@react-spring/web';
import debounce from 'lodash.debounce';
import Storage from '../storage/Storage';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {translate} from '../helpers';
import store from '../store';
import {connect} from 'react-redux';
import placeholderImg from '../assets/placeholder.jpg';

const AnimatedView = animated(View);
const AnimatedImage = animated(Image);
const AnimatedBox = animated(Box);

const HEADER_TOP = 0;
MaterialCommunityIcons.loadFont();

function Header({animatedValue, height, isHome, imagePath, title, description, hideMenu}) {
    let navigate = useNavigate();

    const [width, setWidth] = useState(window.innerWidth);
    const [carts, setCarts] = useState(0);

    useEffect(() => {
        async function getCarts(){
            const cartVal = await Storage.getCart();
            if(cartVal.length !== carts.length) setCarts(cartVal);
        }
        getCarts();
    })
    
    const isMobile = width <= 768;

    // const placeholderImg = require('../assets/placeholder.jpg');
    // console.log('placeholderImg default', placeholderImg);

    const storeResto = store.getState().restaurant;
    const bannerImage = storeResto.restaurant ? storeResto.restaurant.banner : placeholderImg;
    const logoImage = storeResto.restaurant ? storeResto.restaurant.logo : null;
    
    let MIN_HEADER_TOP = -(height * 0.5);
    let MIN_LOGO_SIZE = height - (MIN_HEADER_TOP * -1) - 130;

    const [{headerTop}, setHeaderTop] = useSpring(() => ({
        headerTop: HEADER_TOP,
    }));
    const [{logoOpacity, logoSize, logoTop}, setLogoProps] = useSpring(() => ({
        logoSize: height * 0.7,
        logoTop: -5,
        logoOpacity: 1,
    }));
    const [{menuTop}, setMenuTop] = useSpring(() => ({
        menuTop: 0,
    }));
    const [{descOpacity}, setDescOpacity] = useSpring(() => ({
        descOpacity: 1,
    }));

    const homepage = (typeof isHome === 'undefined' || isHome) ;

    const dimensions = Dimensions.get('window');
    const imageWidth = dimensions.width;
    const imgURI = homepage
        ? bannerImage
        : (typeof imagePath === 'undefined' || imagePath === null || imagePath === '')
            ? bannerImage
            : imagePath;
    const logoURI = logoImage;

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const handleOnScroll = event => {
        const newHtop = Math.max(MIN_HEADER_TOP, HEADER_TOP - window.scrollY);

        setHeaderTop({headerTop: newHtop});

        setLogoProps({
            logoSize: (height * 0.6) - Math.min(MIN_LOGO_SIZE, window.scrollY),
            config: {duration: 100},
            logoOpacity: newHtop === HEADER_TOP  ? 1 : 0
        });

        setMenuTop({menuTop: Math.min((MIN_HEADER_TOP * -1) - 5, window.scrollY)});
        setDescOpacity({descOpacity: newHtop === HEADER_TOP ? 1 : 0});


        handleEndScroll();        
    }

    const handleEndScroll = useMemo(
        () =>
          debounce(() => {
            const newHtop = Math.max(MIN_HEADER_TOP, HEADER_TOP - window.scrollY);
    
            setLogoProps({
                logoOpacity: newHtop === HEADER_TOP ? 1: 0,
            });
          }, 500),
        []
    );

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        window.addEventListener('scroll', handleOnScroll);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
            window.removeEventListener('scroll', handleOnScroll);
        }
    }, []);

    const onBack = () => navigate(-1);

    const onOpenCart = () => {
        if(carts.length > 0){
            navigate("/orderSummary");   
        }else{
            onEmptyCart();
        }
    }

    const onEmptyCart = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div style={{
                        backgroundColor: "#322923",
                        color: "#fff",
                        padding: '20px',
                        borderRadius: '10px'
                    }}>
                        <h5>{translate("Cart is empty.")}</h5>
                        <div style={{display: 'flex'}}>
                            <div style={{
                                backgroundColor: "#fff",
                                color: "#333",
                                borderRadius: '5px',
                                padding: "5px",
                                width: '100%',
                                cursor: 'pointer',
                                textAlign: 'center',
                                marginRight: '5px'
                            }} onClick={onClose}>
                                {translate('Ok')}
                            </div>
                        </div>
                    </div>
                );
            },
        });
    };

    const parent = {
        height : '100%',
        width : '100%',
        transform : [ { scaleX : 2 } ],
        borderBottomStartRadius : height,
        borderBottomEndRadius : height,
        // shadowColor: "#000",
        // shadowOpacity: 0.5,
        // shadowRadius: 10,
        //shadowOffset: { width: 2, height: 2 },
        overflow : 'hidden',
        backgroundColor: 'transparent',
    };
    const child = {
        flex : 1,
        transform : [ { scaleX : 0.5 } ],
        alignItems : 'center',
        justifyContent : 'center',
        backgroundColor: 'transparent'
    }

    return(
        <AnimatedView style={{
                position: 'fixed',
                top: headerTop,
                zIndex: 99,
                backgroundColor: 'transparent',
                width: isMobile ? width : 445,
                //height: headerHeight,
                height,
            }}>
            <Box style={parent}>
                <Box style={child}>
                    <Image 
                        source={{uri: imgURI}}
                        fallbackSource={{uri: placeholderImg}}
                        width={imageWidth}
                        resizeMode="cover"
                        style={{
                            overflow: "hidden",
                            height,
                            top: 0
                        }}
                    />
                    <Box style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: '#000',
                            opacity: (typeof isHome === 'undefined' || isHome) ? 0.2 : 0.4,
                            position: 'absolute',
                        }}
                    />
                </Box>
            </Box>
            <Box width="100%" height="100%" style={{
                position: 'absolute',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                {(typeof description === 'undefined' || !description) && logoURI !== null && (
                    <AnimatedImage 
                        source={{uri: logoURI}}
                        width={imageWidth * 0.8}
                        marginLeft= '3%'
                        resizeMode="contain"
                        style={{
                            opacity: logoOpacity,
                            height: logoSize,
                            top: logoTop
                        }}
                    />
                )}
                {(typeof isHome !== 'undefined' && !isHome) && (
                    <>
                         
                        <AnimatedBox width="80%" height={70} style={{
                            position: 'absolute',
                            top: menuTop,
                            marginTop: '5%',
                            marginLeft: '-15%',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}>
                            <Pressable onPress={onBack}>
                                <HStack>
                                    <MaterialCommunityIcons color="#fff" name="chevron-left" size={31} />
                                    <Text color="#fff" fontSize="xl">
                                        {title}
                                    </Text>
                                </HStack>
                            </Pressable>
                   
                        </AnimatedBox>
                       
                        {typeof description !== 'undefined' && description !== '' && description !== null && (
                            <AnimatedBox px="7%" mt={10} style={{opacity: descOpacity}}>
                                <Text lineHeight="sm" numberOfLines={6} color="#fff" fontSize="sm">
                                    {description}
                                </Text>
                            </AnimatedBox>
                        )}
                    </>
                )}

                {typeof hideMenu === 'undefined' && !hideMenu && (
                    <AnimatedBox width={50} height={70} style={{
                        //backgroundColor: 'red',
                        position: 'absolute',
                        top: menuTop,
                        right: 0,
                        marginTop: '6%',
                        marginRight: '11%',
                    }}>
                        <Button colorScheme="transparent" onPress={onOpenCart}>
                            <Image src={require('../assets/homepage/Menu.png')} alt="menu" height={50} width={50} />
                        </Button>
                        {carts.length > 0 && (
                            <Circle size="23px" position="absolute" top={-4} right={-4} style={{backgroundColor: '#df2351'}}>
                                <Text color="#fff" fontSize="sm">{carts.length}</Text>
                            </Circle>
                        )}
                    </AnimatedBox>
                )}
            </Box>
        </AnimatedView>
    );
}
const styles = StyleSheet.create({
    container: {
        position: 'fixed',
        top: 0,
        zIndex: 99,
        //width: '100%',
        backgroundColor: 'red',
        margin:0,
        // paddingTop: env(safe-area-inset-top), 
        // paddingRight:env(safe-area-inset-right),
        // paddingBottom:env(safe-area-inset-bottom), 
        // paddingLeft:env(safe-area-inset-left),

    },
    cursor: 'pointer',
});

export default Header;