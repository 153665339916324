import { spawn } from 'redux-saga/effects';
import restaurantSaga from './restaurant/sagas';
import categoriesSaga from './categories/sagas';
import menusSaga from './menus/sagas';
import cartsSaga from './carts/sagas';
import ordersSaga from './orders/sagas';
import paymentsSaga from './payments/sagas';

export default function* rootSaga() {
    yield spawn(restaurantSaga)
    yield spawn(categoriesSaga)
    yield spawn(menusSaga)
    yield spawn(cartsSaga)
    yield spawn(ordersSaga)
    yield spawn(paymentsSaga)
}