import axios from "axios";
import storage from '../storage/Storage';

const baseURL = "https://api.staging.tahuaqr.co.nz";
const api = "/api";
const version = "/v1/public";
const routeCategories = 'categories';
const routeMenus = 'menus';
const routeOrders = 'orders';
const routeResto = 'restaurant';
const routePayment = 'payment';
const routeSettings = 'settings';

const headerOptions = (restoId = 1) => {
    return {
        headers: {
          'Content-Type': 'application/json',
          'X-RESTAURANT-ID': restoId,
        },
    };
};

export default {
    getRestoDetails(payload){
       return new Promise((resolve, reject) => {
            let path = `${baseURL}${api}${version}/${routeResto}?domain=${payload.domain}`;
            if (payload.branch_alias) path += `&branch_alias=${payload.branch_alias}`;
            console.log("resto path", path);
            axios.get(path, headerOptions())
            .then((response) => {
                console.log("response resto", response.data.data);
                storage.setRestoDetails(response.data.data);
                resolve(response.data.data);
            })
            .catch(error => {
                console.log("error", error);
                storage.setRestoDetails(null);
                reject(error);
            });
       });
    },

    getRestoSettings(payload) {
        return new Promise( (resolve, reject)  => {
            storage.getRestoDetails()
            .then(response => {
                if (!response) return resolve([]);

                const path = `${baseURL}${api}${version}/${routeSettings}?branch_id=${response.branch_details.id}`;
                return axios.get(path, headerOptions(response.restaurant_details.id))
            })
            .then((response) => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    getCategories() {
        return new Promise( (resolve, reject)  => {
            storage.getRestoDetails()
            .then(response => {
                console.log('getCategories resto details =', response);
                if (!response) return resolve([]);

                const path = `${baseURL}${api}${version}/${routeCategories}?branch_id=${response.branch_details.id}`;
                return axios.get(path, headerOptions(response.restaurant_details.id))
            })
            .then((response) => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    getCategory(id) {
        return new Promise((resolve, reject) => {
            storage.getRestoDetails()
            .then(response => {
                const path = `${baseURL}${api}${version}/${routeCategories}/${id}?branch_id=${response.branch_details.id}`;
                return axios.get(path, headerOptions(response.restaurant_details.id))
            })
            .then((response) => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    getUncategorizedMenus() {
        return new Promise((resolve, reject) => {
            storage.getRestoDetails()
            .then(response => {
                const path = `${baseURL}${api}${version}/${routeMenus}/uncategorized?branch_id=${response.branch_details.id}`;
                return axios.get(path, headerOptions(response.restaurant_details.id))
            })
            .then((response) => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    getMenuItem(id) {
        return new Promise((resolve, reject) => {
            storage.getRestoDetails()
            .then(response => {
                const path = `${baseURL}${api}${version}/${routeMenus}/${id}?branch_id=${response.branch_details.id}`;
                return axios.get(path, headerOptions(response.restaurant_details.id))
            })
            .then((response) => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    postOrder(payload) {
        return new Promise((resolve, reject) => {      
            storage.getRestoDetails()
            .then(response => {      
                const path = `${baseURL}${api}${version}/${routeOrders}`;
                let params = {branch_id: response.branch_details.id, ...payload};
                
                let menuItems = params.items.map(m => {
                    let menu = {...m};
                    let extras = m.extra.map(extra => {
                        if(extra.type === "combo") {
                            let option = {...extra};

                            let items = [];
                            Object.keys(option.items).forEach(key => {
                                option.items[key].forEach(item => {
                                    items.push(item);
                                })
                            })
                            option.items = items;
                            return option;
                        } else {
                            return extra;
                        }
                    });
                    menu.extra = extras;
                    return menu;
                });
                params.items = menuItems;

                return axios.post(path, params, headerOptions(response.restaurant_details.id));
            })
            .then((response) => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    getClientSecret(payload) {
        return new Promise((resolve, reject) => {     
            storage.getRestoDetails()
            .then(response => {      
                const path = `${baseURL}${api}${version}/${routePayment}/secret`;
                const params = {branch_id: response.branch_details.id, ...payload};
                console.log("params", params);
                return axios.post(path, params, headerOptions(response.restaurant_details.id));
            })
            .then((response) => {
                resolve(response.data.data);
                console.log("response of data from api", response.data.data);
            })
            .catch(error => {
                console.log("error in client secret api", error);
                reject(error);
            });
        });

    },

    updatePaymentStatus(payload) {
        return new Promise((resolve, reject) => {      
            storage.getRestoDetails()
            .then(response => {      
                const path = `${baseURL}${api}${version}/${routePayment}/success`;
                const params = {branch_id: response.branch_details.id, ...payload};
                console.log("params", params);
                return axios.post(path, params, headerOptions(response.restaurant_details.id));
            })
            .then((response) => {
                resolve(response.data.data);
                console.log("response of data for payment success from api", response.data.data);
            })
            .catch(error => {
                console.log("error in payment success api", error);
                reject(error);
            });
        });

    },


    async updateOrderStatus(payload) {
        return new Promise((resolve, reject) =>  {   
            storage.getRestoDetails()
            .then(response => {      
                const path = `${baseURL}${api}${version}/${routeOrders}/${payload.order_id}/update_status`;
                const params = {branch_id: response.branch_details.id, ...payload};
                console.log("params", params);
                return axios.put(path, params, headerOptions(response.restaurant_details.id));
            })
            .then((response) => {
                resolve(response.data.data);
                console.log("response of data for order bypass success from api", response.data.data);
            })
            .catch(error => {
                console.log("error in bypass order update api", error);
                reject(error);
            });
        });

    },



};