import AsyncStorage from '@react-native-async-storage/async-storage';

export default {
    async setCart(val) {
        await AsyncStorage.setItem('cart', JSON.stringify(val));
    },
    
    getCart () {
        return new Promise(async (resolve, reject) => {
              try {
                  const value = await AsyncStorage.getItem('cart');
                  return value ? resolve(JSON.parse(value)) : resolve([]);
              } catch (error) {
                  reject(error);
              }
        });
    },

    clearCart() {
        return AsyncStorage.removeItem('cart');
    },

    setCustomerId(val) {
        AsyncStorage.setItem('customer_id', val, (err)=> {
            if(err){
                console.log("an error");
            }
            console.log("success");
        })
    },
    
    getCustomerId () {
        return new Promise(async (resolve, reject) => {
              try {
                  const value = await AsyncStorage.getItem('customer_id');
                  console.log('getCustomerId =', value);
                  return value ? resolve(value) : resolve(null);
              } catch (error) {
                  // Error retrieving data
                  console.log("error is:" + error);
                  reject(error);
              }
        });
    },

    setOrderNumber(val) {
        AsyncStorage.setItem('order_number', val, (err)=> {
            if(err){
                console.log("an error");
            }
            console.log("success");
        })
    },
    
    getOrderNumber() {
        return new Promise(async (resolve, reject) => {
              try {
                  const value = await AsyncStorage.getItem('order_number');
                  console.log('getOrderNumber =', value);
                  return value ? resolve(value) : resolve(null);
              } catch (error) {
                  // Error retrieving data
                  console.log("error is:" + error);
                  reject(error);
              }
        });
    },

    setPrepTime(val) {
        AsyncStorage.setItem('prep_time', val, (err)=> {
            if(err){
                console.log("an error");
            }
            console.log("success");
        })
    },
    
    getPrepTime() {
        return new Promise(async (resolve, reject) => {
              try {
                  const value = await AsyncStorage.getItem('prep_time');
                  console.log('getPrepTime =', value);
                  return value ? resolve(value) : resolve(null);
              } catch (error) {
                  // Error retrieving data
                  console.log("error is:" + error);
                  reject(error);
              }
        });
    },

    setRestoDetails(val) {
        AsyncStorage.setItem('resto_details', JSON.stringify(val), (err)=> {
            if(err){
                console.log("an error");
            }
            console.log("success");
        })
    },
    
    getRestoDetails() {
        return new Promise(async (resolve, reject) => {
              try {
                  const value = await AsyncStorage.getItem('resto_details');
                  //console.log('resto_details =', value);
                  return value ? resolve(JSON.parse(value)) : resolve(null);
              } catch (error) {
                  // Error retrieving data
                  console.log("error is:" + error);
                  reject(error);
              }
        });
    },

    setBranchId(val) {
        AsyncStorage.setItem('branch_id', val, (err)=> {
            if(err){
                console.log("an error");
            }
            console.log("success");
        })
    },
    
    getBranchId() {
        return new Promise(async (resolve, reject) => {
              try {
                  const value = await AsyncStorage.getItem('branch_id');
                  console.log('getBranchid =', value);
                  return value ? resolve(value) : resolve(null);
              } catch (error) {
                  // Error retrieving data
                  console.log("error is:" + error);
                  reject(error);
              }
        });
    },

    setSecretId(val) {
        AsyncStorage.setItem('client_secret', val, (err)=> {
            if(err){
                console.log("an error");
            }
            console.log("success");
        })
    },

    getSecretId() {
        return new Promise(async (resolve, reject) => {
              try {
                  const value = await AsyncStorage.getItem('client_secret');
                  console.log('client_secret =', value);
                  return value ? resolve(value) : resolve(null);
              } catch (error) {
                  // Error retrieving data
                  console.log("error is:" + error);
                  reject(error);
              }
        });
    },

    setPaymentIntentId(val) {
        AsyncStorage.setItem('payment_intentid', val, (err)=> {
            if(err){
                console.log("an error");
            }
            console.log("success");
        })
    },

    getPaymentIntentId() {
        return new Promise(async (resolve, reject) => {
              try {
                  const value = await AsyncStorage.getItem('payment_intentid');
                  console.log('payment_intentid =', value);
                  return value ? resolve(value) : resolve(null);
              } catch (error) {
                  // Error retrieving data
                  console.log("error is:" + error);
                  reject(error);
              }
        });
    },

    setOrderId(val) {
        AsyncStorage.setItem('order_id', val, (err)=> {
            if(err){
                console.log("an error");
            }
            console.log("success");
        })
    },
    
    getOrderId() {
        return new Promise(async (resolve, reject) => {
              try {
                  const value = await AsyncStorage.getItem('order_id');
                  console.log('getOrderId =', value);
                  return value ? resolve(value) : resolve(null);
              } catch (error) {
                  // Error retrieving data
                  console.log("error is:" + error);
                  reject(error);
              }
        });
    },

    setOverallTotal(val) {
        AsyncStorage.setItem('total', val, (err)=> {
            if(err){
                console.log("an error");
            }
            console.log("success");
        })
    },
    
    getOverallTotal() {
        return new Promise(async (resolve, reject) => {
              try {
                  const value = await AsyncStorage.getItem('total');
                  console.log('total =', value);
                  return value ? resolve(value) : resolve(null);
              } catch (error) {
                  // Error retrieving data
                  console.log("error is:" + error);
                  reject(error);
              }
        });
    },

    setPaymentMethod(val) {
        AsyncStorage.setItem('payment_method', val, (err)=> {
            if(err){
                console.log("an error");
            }
            console.log("success");
        })
    },
    
    getPaymentMethod() {
        return new Promise(async (resolve, reject) => {
              try {
                  const value = await AsyncStorage.getItem('payment_method');
                  console.log('payment_method =', value);
                  return value ? resolve(value) : resolve(null);
              } catch (error) {
                  // Error retrieving data
                  console.log("error is:" + error);
                  reject(error);
              }
        });
    },
}