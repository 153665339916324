import constants from "../constants";
import { put, takeEvery, all, call } from 'redux-saga/effects';
import api from '../../webservice/Client';

function* getRestaurantDetails({payload}) {
    try {
        yield put({ type: constants.RESTAURANT_SET_LOADING, payload: true });
        yield put({ type: constants.CATEG_SET_LOADING, payload: true });
        const details = yield call(api.getRestoDetails, payload);
        yield put({ type: constants.RESTAURANT_SET_LOADING, payload: false });
        yield put({ type: constants.RESTAURANT_SET_DETAILS, payload: details });

        const settings = yield call(api.getRestoSettings);
        yield put({ type: constants.RESTAURANT_SET_SETTINGS, payload: settings });
        
        const response = yield call(api.getUncategorizedMenus);
        yield put({ type: constants.MENU_SET_UNCATEGORIZED_MENUS, payload: response });

        const categories = yield call(api.getCategories);
        yield put({ type: constants.CATEG_SET_CATEGORIES, payload: categories });
        yield put({ type: constants.CATEG_SET_LOADING, payload: false });
    } catch(e) {
        yield put({ type: constants.RESTAURANT_SET_LOADING, payload: false });
        yield put({ type: constants.CATEG_SET_LOADING, payload: false });
    }
}

function* getSettings(action) {
    try {
        const response = yield call(api.getRestoSettings);
        yield put({ type: constants.RESTAURANT_SET_SETTINGS, payload: response });
    } catch(e) {
        //
    }
}

export default function* () {
    yield all([
        takeEvery(constants.RESTAURANT_GET_DETAILS, getRestaurantDetails),
        takeEvery(constants.RESTAURANT_GET_SETTINGS, getSettings),
    ]);
}
