import React, {useEffect, useState} from 'react';
import { StyleSheet, TextInput, Alert } from 'react-native';
import { useNavigate } from "react-router-dom";
import { Stack,Box, HStack, Text, Heading, Image, Center, Icon, Button} from 'native-base';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import '../../styles/styles.css';
import MainContainer from '../layouts/Container';
import {translate, totalMenuPrice} from '../helpers';
import { connect, useDispatch } from 'react-redux';
import ordersActions from '../store/orders/actions';
import paymentsActions from '../store/payments/actions';
import restoActions from '../store/restaurant/actions';
import validator from 'validator';
import storage from '../storage/Storage';

const ORDERTYPE = {
    dine: 'dine_in',
    pickup: 'pickup',
    takeaway: 'takeaway',
    delivery: 'delivery',
}

function OrderConfirmation(props) {
    let dispatch = useDispatch();
    let navigate = useNavigate();

    MaterialCommunityIcons.loadFont();

    const [number, onChangeNumber] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [orderType, setOrderType] = React.useState(ORDERTYPE.dine);
    const [settings, setSettings] = React.useState(props.restaurant.settings);
    const [paymentCtrDineIn, setPaymentCtrDineIn] = React.useState(0);
    const [paymentCtrTakeaway, setPaymentCtrTakeaway] = React.useState(0);
    const [paymentCtrPickup, setPaymentCtrPickup] = React.useState(0);
    const [paymentCtrDelivery, setPaymentCtrDelivery] = React.useState(0);
    const goHome = () => {
        navigate("/");
    };

    useEffect(() => {
        dispatch(restoActions.getSettings());
    }, []);

    useEffect(() => {
        setSettings(props.restaurant.settings);
    }, [props.restaurant.settings]);

    useEffect(() => {
        console.log("props.restaurant", props.restaurant);
        console.log("dinein payment method settings", settings.payment_methods);
        console.log("takeaway payment method settings", settings.takeaway_payment_methods);
        console.log("pickup payment method settings", settings.pickup_payment_methods);
        console.log("delivery payment method settings", settings.delivery_payment_methods);
       
        var paymentCtrD = 0;
        var paymentCtrT = 0;
        var paymentCtrP = 0;
        var paymentCtrDV = 0;
        if(settings.payment_methods && settings.payment_methods.pay_in_till == true){
            paymentCtrD++; 
        }
        if(settings.payment_methods && settings.payment_methods.card == true){
            paymentCtrD++;  
        }
        if( settings.takeaway_payment_methods && settings.takeaway_payment_methods.pay_in_till == true){
            paymentCtrT++; 
        }
        if( settings.takeaway_payment_methods && settings.takeaway_payment_methods.card == true){
            paymentCtrT++;  
        }
        if( settings.pickup_payment_methods && settings.pickup_payment_methods.pay_in_till == true){
            paymentCtrP++; 
        }
        if( settings.pickup_payment_methods && settings.pickup_payment_methods.card == true){
            paymentCtrP++;  
        }
        if( settings.delivery_payment_methods && settings.delivery_payment_methods.pay_in_till == true){
            paymentCtrDV++; 
        }
        if( settings.delivery_payment_methods && settings.delivery_payment_methods.card == true){
            paymentCtrDV++;  
        }
        console.log("counter values", paymentCtrD, paymentCtrT, paymentCtrP, paymentCtrDV);
        setPaymentCtrDineIn(paymentCtrD);
        setPaymentCtrTakeaway(paymentCtrT);
        setPaymentCtrPickup(paymentCtrP);
        setPaymentCtrDelivery(paymentCtrDV);

        if(paymentCtrD === 0 && paymentCtrT > 0) {
            setOrderType(ORDERTYPE.takeaway);
        }
        if(paymentCtrD === 0 && paymentCtrT === 0 && paymentCtrP > 0 && paymentCtrDV === 0) {
            setOrderType(ORDERTYPE.pickup);
        }
        if(paymentCtrD === 0 && paymentCtrT === 0 && paymentCtrP === 0 && paymentCtrDV > 0) {
            setOrderType(ORDERTYPE.delivery);
        }
        console.log("order type", orderType);

    }, [settings]);

   const onPlaceOrder =  async () => {
        switch(orderType) {
            case ORDERTYPE.dine:
                if(number === '' && orderType === ORDERTYPE.dine) {
                    alert("Please enter a table number.");
                    break; 
                }

                if(props.carts.details.length > 0){
                    let gross_price = 0;
                    for (const menu of props.carts.details) {
                        gross_price += totalMenuPrice(parseFloat(menu.price), menu.qty, menu.extra);
                    }

                    let payload = {
                        "order_type" : orderType,
                        "table_num" : number === '' ? 0 : number,
                        items: props.carts.details,
                        gross_price
                    };

                    dispatch(ordersActions.setOrderConfirmationDetails(payload));

                        if(paymentCtrDineIn == 1){
                            //skip and go to next payge
                            if(settings.payment_methods && settings.payment_methods.pay_in_till == true){
                                storage.setPaymentMethod('till');
                    
                                let payload1 = payload;
                                payload1.payment_type = 'till';
                                payload1.onFinished = () => navigate("/thankyou");
                    
                                dispatch(ordersActions.sendOrderDetails(payload1));
                            }

                            if(settings.payment_methods && settings.payment_methods.card == true){
                               
                                storage.setPaymentMethod('card');
                                navigate("/checkout");
                            }

                        }else{
                           
                            navigate("/payment");
                        }
                    
                }
                break;

            case ORDERTYPE.takeaway:
                if(orderType === ORDERTYPE.takeaway && mobile.length > 0 && !validator.isMobilePhone(mobile)) {
                    alert('Please enter a valid mobile number.');
                    break;
                }

                if(props.carts.details.length > 0){
                    let gross_price = 0;
                    for (const menu of props.carts.details) {
                        gross_price += totalMenuPrice(parseFloat(menu.price), menu.qty, menu.extra);
                    }

                    let payload = {
                        "order_type" : orderType,
                        "table_num" : number === '' ? 0 : number,
                        items: props.carts.details,
                        gross_price
                    };

                    if(mobile.length > 0) {
                        payload.mobile = mobile;
                    }

                    dispatch(ordersActions.setOrderConfirmationDetails(payload));

                        if(paymentCtrTakeaway == 1){
                            //skip and go to next payge
                            if(settings.takeaway_payment_methods && settings.takeaway_payment_methods.pay_in_till == true){
                                storage.setPaymentMethod('till');
                    
                                let payload1 = payload;
                                payload1.payment_type = 'till';
                                payload1.onFinished = () => navigate("/thankyou");
                    
                                dispatch(ordersActions.sendOrderDetails(payload1));
                            }
                            if(settings.takeaway_payment_methods && settings.takeaway_payment_methods.card == true){
                               
                                storage.setPaymentMethod('card');
                                navigate("/checkout");
                            }

                        }else{
                           
                            navigate("/payment");
                        }
                 }
                    
                break;
            case ORDERTYPE.pickup:
                break;
                break;
            case ORDERTYPE.delivery:
                break;
        }
   }

    const onSetOrderType = value => {
        setOrderType(value);
    }

    const handleChange = (value) => {
        if (/[^0-9]/.test(value)) { 
            alert('Input numeric values only.');
        }else{
            onChangeNumber(value);
        }
    }

    const handleMobileChange = (value) => {
        setMobile(value);
    }

    console.log('paymentCtrTakeaway', paymentCtrTakeaway);

    return(
         <MainContainer
            title='Back'
            isHome={false}
            hideLogo={false}
            imagePath={location.state?.category.photo}>
              <Center>
                 <Box bg="#FFFFFF" padding={4} height='100%' width='98%' borderRadius={10}>
                      <Box mb={4} height="5%">
                            <Heading style={styles.content} fontWeight="bold"> {translate('Order Type')} </Heading>
                      </Box>
                      {/* {paymentCtrDineIn >= 1 && paymentCtrTakeaway >= 1 &&  */}
                      <Box>
                          <HStack justifyContent="space-evenly">
                            {paymentCtrDineIn > 0 && (
                                <Stack>
                                    <Button  onPress={() => onSetOrderType(ORDERTYPE.dine)}   height={50} width={50} top={30} bg="transparent" colorScheme="transparent">
                                        <Image src={(orderType === ORDERTYPE.dine) ? require('../assets/orderconfirmation/dine-in_on.png') : require('../assets/orderconfirmation/dine-in_off.png')} alt="dine"  height={50} width={50} />
                                    </Button>
                                    <Text mt={35} style={styles.title} >{translate('Dine-In')}</Text>
                                </Stack>
                            )}
                            {paymentCtrTakeaway > 0 && (
                                <Stack>
                                    <Button  onPress={() => onSetOrderType(ORDERTYPE.takeaway)}    height={50} width={50}  top={30} bg="transparent" colorScheme="transparent">
                                        <Image  src={(orderType === ORDERTYPE.takeaway) ? require('../assets/orderconfirmation/pick-up_on.png') : require('../assets/orderconfirmation/pick-up_off.png')} alt="takeaway"   height={50} width={50} />
                                    </Button>
                                    <Text mt={35}  ml={-2} style={styles.title}>{translate('TakeAway')}</Text>
                                </Stack>
                            )}
                            {paymentCtrPickup > 0 && (
                            <Stack>
                                <Button  onPress={() => onSetOrderType(ORDERTYPE.pickup)}  disabled={true}  height={50} width={50}  top={30} bg="transparent" colorScheme="#808080">
                                    <Image  src={orderType === ORDERTYPE.pickup ? require('../assets/orderconfirmation/pick-up_on.png') : require('../assets/orderconfirmation/pickup_disabled.png')} alt="pickup"   height={50} width={50} />
                                </Button>
                                <Text mt={35} style={styles.title}>{translate('Pickup')}</Text>
                            </Stack>
                             )}
                            {paymentCtrDelivery> 0 && (
                            <Stack>
                                <Button  onPress={() => onSetOrderType(ORDERTYPE.delivery)} disabled={true} height={50} width={50} top={30} bg="transparent" colorScheme="#808080">
                                    <Image src={orderType === ORDERTYPE.delivery ? require('../assets/orderconfirmation/delivery_on.png') : require('../assets/orderconfirmation/delivery_disabled.png')} alt="delivery"   height={50} width={50}  />
                                </Button>
                                <Text mt={35}  style={styles.title}>{translate('Delivery')}</Text>
                            </Stack>
                             )}
                          </HStack>
                      </Box>
                      {/* } */}

                     {orderType == ORDERTYPE.dine && paymentCtrDineIn > 0 && (
                        <Box paddingTop={10} justifyContent="space-evenly">
                            <Heading style={styles.content} fontWeight="bold"> {translate('Table Number')} </Heading> 
                            <TextInput
                                style={styles.input}
                                onChangeText={handleChange}
                                value={number}
                                placeholder="Enter table number"
                                keyboardType="numeric"
                                maxLength={3}
                            />
                        </Box>
                     )}

                     {(orderType == ORDERTYPE.pickup || (orderType == ORDERTYPE.takeaway && paymentCtrTakeaway > 0 )) && (
                        <Box paddingTop={10} justifyContent="space-evenly">
                            <Heading style={styles.text}>{translate('Enter your mobile number if you want to be notified that your order is ready to pickup. Your mobile number will be deleted from the system after the text message is sent successfully.')} </Heading> 
                            <TextInput
                                style={styles.input}
                                onChangeText={handleMobileChange}
                                value={mobile}
                                placeholder="Enter mobile number"
                                keyboardType="phone-pad"
                            />
                        </Box>
                     )}

                      <Box>
                        <HStack paddingTop={10} alignItems="center" justifyContent="space-evenly" my={2}>
                            <Button disabled={props.orders.loading} borderRadius="20" height="8" width="50%" top={0} bg={props.orders.loading ? "#808080" : "#4d3728"} onPress={onPlaceOrder} colorScheme="transparent">
                                <Text style={styles.order}>{props.orders.loading ? translate('Sending...') : translate('Place Order')}</Text>
                            </Button>
                            <Button borderRadius="20" height="8" width="50%" top={0} bg="light.100" onPress={goHome} colorScheme="transparent">
                                <Text  style={styles.title}>{translate('Cancel')}</Text>
                            </Button>
                        </HStack>
                    </Box>
                  </Box>
              </Center>
        </MainContainer>
    );
}

const styles = StyleSheet.create({
    menuContainer: {
        marginBottom: 100
    },
    footer: {
        backgroundColor: '#2b3036',
        width: '100%',
        fontStyle: "Helvetica-NeueLT",
        fontSize:10,
        fontWeight:"normal",
        color:"#f5f5f4"
    },
    gridContainer:{
        backgroundColor: '#46362a',
        width: '100%',
    },
    heading:{
        fontStyle: "Helvetica-NeueLT",
        fontSize:25,
        fontWeight:"normal",
        color:"#f5f5f4",
        width:230
    },
    title:{
        fontStyle: "Helvetica-NeueLT",
        fontSize:15,
        fontWeight:"normal",
        color:"#4d3728",
        mt:17,
    },
    mainContainer:{
        height: 156,
    },
    content: {
        fontStyle: "Helvetica-NeueLT",
        fontSize:25,
        fontWeight:"normal",
        color:"#4d3728",
        width:165,
    },
    text: {
        fontStyle: "Helvetica-NeueLT",
        fontSize:14,
        fontWeight:"normal",
        color:"#4d3728",
        textAlign: 'left',
        marginHorizontal: 12,
    },
    tableNumber:{
        fontStyle: "Helvetica-NeueLT",
        fontSize:25,
        fontWeight:"normal",
        color:"#4d3728",
        width:195,
        position:"absolute",
        top:-35,
        right:0,
    },
    order: {
        color: '#ffffff',
        fontStyle: "Helvetica-NeueLT",
        fontSize:15,
        fontWeight:"normal",
    },
    input: {
        height: 40,
        margin: 12,
        borderWidth: 1,
        padding: 10,
    },
    img:{
     paddingLeft:10,
    },
    cursor: 'pointer',
});

function mapStateToProps(state) {
    return {
        orders: state.orders,
        carts: state.carts,
        payments: state.payments,
        restaurant: state.restaurant,
    }
  }
  
export default connect(mapStateToProps)(OrderConfirmation);