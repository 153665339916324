import constants from "../constants";
import { put, takeEvery, all, call } from 'redux-saga/effects';
import api from '../../webservice/Client';
import Storage from "../../storage/Storage";
import store from '../index';
import paymentsActions from '../payments/actions';

function* sendOrderDetails({payload}) {
    try {
        let params = {...payload};
        yield put({ type: constants.ORDER_SET_LOADING, payload: true });
        const customerId = yield call(Storage.getCustomerId);
        if (customerId) {
            params.customer_id = customerId;
        }
        params.payment_type = payload.payment_type === 'till' ? 'pay_in_till' : payload.payment_type;
        console.log("Order payload", params);

        const order = yield call(api.postOrder, params);

        yield call(Storage.setCustomerId, order.customer_id);
        yield call(Storage.setOrderNumber, order.order_number);
        yield call(Storage.setPrepTime, order.total_preparation_time);
        yield call(Storage.setOrderId, order.id);

        yield call(Storage.clearCart);
        yield put({ type: constants.CART_SET_DETAILS, payload: [] });

        yield put({ type: constants.ORDER_SET_ORDER_DETAILS, payload: order });

        yield put({ type: constants.ORDER_SET_LOADING, payload: false });

        if(['card', 'apple_pay', 'google_pay'].includes(payload.payment_type)) {
            let payload = {
                "order_id": order.id,
                "payment_id": store.getState().payments.details.id,
                "gateway": "stripe",
            };
            store.dispatch(paymentsActions.onPaymentSuccess(payload));
        }

        if(typeof payload.onFinished !== 'undefined') {
            payload.onFinished();
        }
        yield put({ type: constants.ORDER_GET_ORDERCONIFRMATION_DETAILS, payload: null});
    } catch(e) {
        console.log('order error', e);
        yield put({ type: constants.ORDER_SET_LOADING, payload: false });
    }
}

export default function* () {
    yield all([
        takeEvery(constants.ORDER_SEND_ORDER_DETAILS, sendOrderDetails),
    ]);
}
