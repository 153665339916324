import React, {useState, useEffect} from 'react';
import {StyleSheet, View} from 'react-native';
import {Center} from 'native-base';
import Header from '../components/Header';

export default function MainContainer({children, isHome, imagePath, title, description, hideMenu, hideLogo}) {
    const [width, setWidth] = useState(window.innerWidth);
    const [headerHeight, setHeaderHeight] = useState(window.innerHeight * 0.35);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        //setHeight(window.innerHeight);
        setHeaderHeight(window.innerHeight * 0.35);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return(
        <Center style={{minHeight: '100%'}}>
            <View style={ [styles.container, {
                width: isMobile ? width : 445,
                minHeight: '100%'
            }]}>
                <Header height={headerHeight} isHome={isHome} imagePath={imagePath} title={title} description={description} hideMenu={hideMenu} />
                <View style={{backgroundColor: '#46362a', paddingTop: headerHeight - 120, minHeight: '100%'}}>
                    <View style={styles.children}>
                        {children}
                    </View>
                </View>
            </View>
        </Center>
    );
};

const styles = StyleSheet.create({
    container: {
        minHeight: '100%',
        backgroundColor: 'transparent',
        alignSelf: 'center',
        justifyContent: 'center',
        flex: 1,
    },
    children: {
       backgroundColor: '#46362a',
       padding:30,
       paddingHorizontal: 10,
       paddingTop: 140,
    },
    parent:{
        color: "#FFFFFF",
        alignSelf: 'center',
        backgroundColor:'transparent'
    },
});
