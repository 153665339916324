import React from 'react';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import { NativeBaseProvider, extendTheme, Box } from 'native-base';
import MenuItemList from "./src/screens/MenuItemList";
import Home from "./src/screens/Home";
import OrderConfirmation from "./src/screens/OrderConfirmation";
import ThankYou from "./src/screens/ThankYou";
import OrderSummary from "./src/screens/OrderSummary";
import PaymentSetup from './src/screens/PaymentSetup';
import PaymentFailure from './src/screens/PaymentFailure';
import PaymentMethod from './src/screens/PaymentMethod';
import ThankYouDemo from './src/screens/ThankYouDemo';
//import PaymentGooglePay from './components/PaymentGooglePay';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import SafeAreaView from 'react-native-safe-area-view';
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Provider } from 'react-redux';
import store from './src/store';
import './styles/styles.css';
import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: '5FOVDlPlqWSilGGzNjqt5npOi34l9eZn',
//     cluster: 'mt1',
//     wsHost: 'api.tahuaqr.co.nz',
//     wsPort: 6001,
//     wssPort: 6001,
//     forceTLS: true,
//     encrypted: true,
//     disableStats: true,
//     enabledTransports: ['ws', 'wss']
// });

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'adfasdfsfstagingadf',
    cluster: 'mt1',
    wsHost: 'api.staging.tahuaqr.co.nz',
    wsPort: 3030,
    forceTLS: false,
    enabledTransports: ['ws']
});

const newColorTheme = {
    brand: {
        900: '#8287af',
        800: '#7c83db',
        700: '#b3bef6',
    },
};

const theme = extendTheme({
    colors: newColorTheme
});


function App() {
    const isMobile = window.innerWidth <= 768;
    return (
        <SafeAreaProvider style={{ flex: 1 }}>
            <SafeAreaView style={{ flex: 1,  backgroundColor: isMobile ? '#46362a' : '#fff', viewport:'cover' }} forceInset={{ top: 'always' }}>
                <Provider store={store}>
                    <NativeBaseProvider theme={theme}>
                        <SkeletonTheme baseColor="#312924" highlightColor="#201b17">
                            <div id='body' style={{
                                flex: 1,
                                height: '100%',
                                justifyContent: 'center',
                                overflow: 'hidden',
                                position: 'relative',
                            }}>
                                <Router>
                                    <Routes>
                                        <Route path="/" exact element={<Home />} />
                                        <Route path="/menuItem/:categoryId" element={<MenuItemList />} />
                                        <Route path="/orderConfirm" element={<OrderConfirmation />} />
                                        <Route path="/thankYou" element={<ThankYou />} />
                                        <Route path="/orderSummary" element={<OrderSummary />} />
                                        <Route path="/checkout"  element={<PaymentSetup />}/>
                                        <Route path="/fail"  element={<PaymentFailure />}/>
                                        <Route path="/payment"  element={<PaymentMethod />}/>
                                        <Route path="/thankyou" element={<ThankYouDemo />} />
                                        {/* <Route path="/paymentgoogle" element={<PaymentGooglePay />} /> */}
                                    </Routes>
                                </Router>
                            </div>
                        </SkeletonTheme>
                    </NativeBaseProvider>
                </Provider>
            </SafeAreaView>
        </SafeAreaProvider>
    );
}
export default App;