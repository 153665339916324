import { combineReducers } from 'redux';
import restaurantReducer from './restaurant/reducers';
import categoriesReducer from './categories/reducers';
import menusReducer from './menus/reducers';
import cartsReducer from './carts/reducers';
import ordersReducer from './orders/reducers';
import paymentsReducer from './payments/reducers';

export default combineReducers({
    restaurant: restaurantReducer,
    categories: categoriesReducer,
    menus: menusReducer,
    carts: cartsReducer,
    orders: ordersReducer,
    payments: paymentsReducer,
});