import React, {useEffect,useState} from 'react';
import {Dimensions} from 'react-native';
import { Select, HStack, VStack, Pressable, Box, Circle, Text, Image, Center, Icon, Button} from 'native-base';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useSpring, animated } from '@react-spring/web';
import {totalMenuPrice} from '../helpers';

const AnimatedBox = animated(Box);
const AnimatedVStack = animated(VStack);
const AnimatedHStack = animated(HStack);
const AnimatedImage = animated(Image);
MaterialCommunityIcons.loadFont();

export default function OrderItem({index, menu, onPress, onPlus, onMinus, onDeleteItem}) {
    const dimensions = Dimensions.get('window');
    const fullImageW = Math.round(dimensions.width * 0.9);

    const [{ width, borderBottomRadius, opacity1, opacity2, displaySmallDesc, displayLongDesc }, setSelectedProps] = useSpring(() => ({
        displaySmallDesc: 'flex',
        width: 150,
        borderBottomRadius: 10,
        opacity1: 1,
        opacity2: 0,
        displayLongDesc: 'none',
    }));
    
    const customSortOrder = ['combo', 'variation', 'add-on', 'dietary'];
    menu.extra.sort((a, b) => {
        const typeA = customSortOrder.indexOf(a.type);
        const typeB = customSortOrder.indexOf(b.type);
        return typeA - typeB;
    });

    let extras = [];
    menu.extra.forEach(extra => {
        if(extra.type === "combo") {
            const keys = Object.keys(extra.items);
            keys.forEach(key => {
                extra.items[key].forEach(item => {
                    extras.push(item.name);
                })
            })
        } else {
            extra.items.forEach(item => {
                extras.push(item.name);
            });
        }
    })

    const price = totalMenuPrice(parseFloat(menu.price), menu.qty, menu.extra);

    return(
        <Box width="95%" mb={4}>
            <Pressable >
                <AnimatedHStack
                    style={{
                        borderRadius: 10,
                        borderBottomRightRadius: borderBottomRadius,
                        borderBottomLeftRadius: borderBottomRadius,
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                    }}
                >
                        <Box style={{width: '60%'}}>
                            <Text color="#322923" fontSize="sm" fontWeight="bold" numberOfLines={0}>
                                {menu.menu_name}
                            </Text>
                            {extras.map((extra, i) => {
                                return (
                                    <Box key={`item${i}`}>
                                        <Text color="#aaa" fontSize="xs">{extra}</Text>
                                    </Box>
                                );
                            })}
                            {menu.notes.length > 0 && (
                                <Box my={1}>
                                    <Text color="#aaa" fontSize="xs" fontStyle="italic">Notes: {menu.notes}</Text>
                                </Box>
                            )}
                            <HStack space={2}>
                                <Pressable onPress={onMinus} style={{cursor: 'pointer'}}>
                                    <Circle size="30px" bg='#fffbf2'>
                                        <MaterialCommunityIcons color="#322923" name="minus" size={20} />
                                    </Circle>
                                </Pressable>
                                <Box><Text color="#322923" fontSize="md" fontWeight="bold">{menu.qty}</Text></Box>
                                <Pressable onPress={onPlus} style={{cursor: 'pointer'}}>
                                    <Circle size="30px" bg='#fffbf2'>
                                        <MaterialCommunityIcons color="#322923" name="plus" size={20} />
                                    </Circle>
                                </Pressable>
                            </HStack>
                        </Box>
                        <HStack alignItems="flex-start" justifyContent="space-between" my={2}>
                            <Box><Text color="#5a3b28" fontSize="md" fontWeight="bold">${parseFloat(price).toFixed(2)}</Text></Box>
                            <Pressable onPress={onDeleteItem} style={{cursor: 'pointer'}}>
                                <Circle size="30px" bg='#fffbf2'>
                                    <MaterialCommunityIcons color="#322923" name="delete" size={20} />
                                </Circle>
                            </Pressable>
                        </HStack>
                </AnimatedHStack>
            </Pressable>
        </Box>
    );
};