import constants from "../constants";
import { put, takeEvery, all, call } from 'redux-saga/effects';
import api from '../../webservice/Client';
import store from "..";

function* getCategories() {
    try {
        yield put({ type: constants.CATEG_SET_LOADING, payload: true });
        const categories = yield call(api.getCategories);
        console.log('Saga Categories', categories);
        yield put({ type: constants.CATEG_SET_CATEGORIES, payload: categories });
        yield put({ type: constants.CATEG_SET_LOADING, payload: false });
    } catch(e) {
        yield put({ type: constants.CATEG_SET_LOADING, payload: false });
    }
}

function* getCategory({payload}) {
    try {
        yield put({ type: constants.CATEG_SET_LOADING, payload: true });
        const category = yield call(api.getCategory, payload);

        let categoryMenus = {...store.getState().categories.categoryMenus};
        categoryMenus[payload] = category;
        console.log('Saga Category', category);
        console.log('Saga categoryMenus', categoryMenus);

        yield put({ type: constants.CATEG_SET_CATEGORY_MENUS, payload: categoryMenus });
        yield put({ type: constants.CATEG_SET_LOADING, payload: false });
    } catch(e) {
        yield put({ type: constants.CATEG_SET_LOADING, payload: false });
    }
}

export default function* () {
    yield all([
        takeEvery(constants.CATEG_GET_CATEGORIES, getCategories),
        takeEvery(constants.CATEG_GET_CATEGORY_MENUS, getCategory),
    ]);
}
