import React, {useEffect, useMemo, useState, useRef} from 'react';
import { View, Text, ScrollView } from 'react-native';
import { useParams } from "react-router-dom";
import { Center} from 'native-base';
import '../../styles/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainContainer from '../layouts/Container';
import MenuItem from '../components/MenuItem';
import { useLocation } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import actions from '../store/categories/actions';
import cartsActions from '../store/carts/actions';
import Skeleton from 'react-loading-skeleton';

function MenuItemList(props) {
    const { categoryId } = useParams();
    const [menus, setMenus] = useState([]);
    const [expandedItem, setExpandedItem] = useState(null);

    let dispatch = useDispatch();
    let location = useLocation();

    useEffect(() => {
        dispatch(actions.getCategory(categoryId));
        dispatch(cartsActions.getCartDetails());
    }, []);

    useEffect(() => {
        if(props.restaurant.branch) {
            window.Echo.channel(`branch.${props.restaurant.branch.id}`)
                .listen('.menu.updated', (event) => {
                    dispatch(actions.getCategory(categoryId));
                })
                .listen('.menu.deleted', (event) => {
                    dispatch(actions.getCategory(categoryId));
                })
                .listen('.menu.recurrence.updated', (event) => {
                    dispatch(actions.getCategory(categoryId));
                });
        }
    }, [props.restaurant.branch]);

    useEffect(() => {
        setTimeout(() => {
            if(Object.keys(props.categories.categoryMenus).length > 0 && typeof props.categories.categoryMenus[categoryId] !== 'undefined') {
                setMenus(props.categories.categoryMenus[categoryId].menus);
            }
        }, 50);
    }, [categoryId, props.categories.categoryMenus]);


    const onAddedToCart = async (order) => {
        if(Object.keys(order).length > 0){
            console.log("value of order", order);
            dispatch(cartsActions.addToCart(order));
        }
    }
    
    return (
        <MainContainer
            title={location.state?.category.name}
            description={location.state?.category.description}
            isHome={false}
            hideLogo={false}
            imagePath={location.state?.category.photo}>
            {props.categories.loading && menus.length === 0 ? (
                <Center>
                    {[...Array(6).keys()].map((key, index) => {
                        return (
                            <View key={key} style={{width: '95%', marginBottom: 20}}>
                                <Skeleton style={{
                                    width: '100%',
                                    height: 150,
                                    borderRadius: 10
                                }} />
                            </View>
                        )
                    })}
                </Center>
            ) : (
                <Center>
                    {menus.map(menu => {
                        let itemInCartQty = 0;
                        if (props.carts.details.length > 0) {
                            const filter = props.carts.details.filter(item => {
                                return item.menu_item_id == menu.id
                            });
                            for (const item of filter) {
                                itemInCartQty += parseInt(item.qty);
                            }
                        }

                        return (
                             <MenuItem
                                key={menu.id}
                                menu={menu}
                                itemInCartQty={itemInCartQty}
                                onConfirm={onAddedToCart}
                                selected={expandedItem && expandedItem.id === menu.id}
                                onPress={(menu) => {
                                    if(menu) {
                                        console.log('Pressed menu', menu.name);
                                        setExpandedItem(expandedItem && expandedItem.id === menu.id ? null : menu);
                                    } else {
                                        setExpandedItem(null);
                                    }
                                }}
                            />
                        );
                    })}
                </Center>
            )}
        </MainContainer>
    );
}

function mapStateToProps(state) {
    return {
        restaurant: state.restaurant,
        categories: state.categories,
        carts: state.carts,
    }
}
  
export default connect(mapStateToProps)(MenuItemList);
