import React, {useEffect, useState} from 'react';
import { StyleSheet, BackHandler } from 'react-native';
import { useNavigate } from "react-router-dom";
import { Stack, Text, Heading, Center, Button} from 'native-base';

import '../../styles/styles.css';
import MainContainer from '../layouts/Container';
import {translate} from '../helpers';
import { connect, useDispatch } from 'react-redux';
import paymentsActions from '../store/payments/actions';
import storage from '../storage/Storage';

function ThankYou(props) {
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const [payment, setPayment] = useState("");

    const goHome = () => {
        navigate("/");
    }; 

    useEffect(() => {
        window.addEventListener('popstate', onBackPress);
    }, []);

    const onBackPress = (e) => {
        e.preventDefault();
        window.location.href = '/';
    }

    return(
         <MainContainer
            title='Success!'
            isHome={true}
            hideMenu={false}
            hideBack={true}
            imagePath={require('../assets/header.jpg')}>
              <Center>
                <Stack>
                    <Center><Heading style={styles.contentHeader}> {translate('Thanks for your order!')} </Heading></Center>
                </Stack>
                <Stack mt={15} >
                    <Heading style={styles.order}> {translate('Your order number is:')} </Heading>
                    <Heading style={styles.order}> {props.orders.details.queue}</Heading>
                </Stack>
                <Stack mt={10}>
                <Heading style={styles.order}> {translate('Please remember your order ID or take a screenshot.')}</Heading>
                </Stack>
                <Stack mt={10}>
                    
                    <Heading style={styles.order}> {translate('Your order should be ready soon.')}</Heading>
                    {/* <Heading style={styles.order}> {`${props.orders.details.total_preparation_time} mins.`}</Heading> */}
                    <Heading style={styles.order}> {props.orders.details.payment_type == 'pay_in_till' ? translate('Please pay at the till.\n') : ''}</Heading>
                    
                </Stack>

                <Stack mt={15} >
                <Heading style={styles.order}> {props.orders.details.order_type == 'takeaway' ? translate('You will receive a text message on your number (' + props.orders.details.mobile + ') when your order is ready to pick up.') : ''}</Heading>
                </Stack>
                 <Stack mt={45}>
                     <Button borderRadius="20" height="8" width="185" top={0} bg="light.100" onPress={goHome} colorScheme="transparent">
                        <Text style={styles.title}>{translate('Menu')}</Text>
                     </Button>
                 </Stack>
            </Center>
        </MainContainer>
    );
}

const styles = StyleSheet.create({
    menuContainer: {
        marginBottom: 100
    },
    footer: {
        backgroundColor: '#2b3036',
        width: '100%',
        fontStyle: "Helvetica-NeueLT",
        fontSize:10,
        fontWeight:"normal",
        color:"#f5f5f4"
    },

    heading:{
        fontStyle: "Helvetica-NeueLT",
        fontSize:25,
        fontWeight:"normal",
        color:"#f5f5f4",
        width:230
    },
    title:{
        fontStyle: "Helvetica-NeueLT",
        fontSize:15,
        fontWeight:"normal",
        color:"#4d3728",
        mt:10,
        ml:7,
    },
    mainContainer:{
        backgroundColor: '#46362a',
        height: 156,
    },
    contentHeader: {
        fontStyle: "Helvetica-NeueLT",
        fontSize:30,
        fontWeight:"normal",
        color:"#f5f5f4",
        size:"md",
    },
    content: {
        fontStyle: "Helvetica-NeueLT",
        fontSize:20,
        fontWeight:"normal",
        width:275,
        color:"#f5f5f4",
    },
    order: {
        color: '#ffffff',
        fontStyle: "Helvetica-NeueLT",
        fontSize:20,
        fontWeight:"normal",
        size:"md",
        textAlign: 'center'
    },
    cursor: 'pointer',
});

function mapStateToProps(state) {
    return {
        orders: state.orders,
        payments: state.payments
    }
  }
  
export default connect(mapStateToProps)(ThankYou);