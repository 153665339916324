import React, {useEffect, useState} from 'react';
import { Box, Heading, Text, Center, HStack, Button} from 'native-base';
import '../../styles/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { StyleSheet } from 'react-native';
import MainContainer from '../layouts/Container';
import {translate, totalMenuPrice} from '../helpers';
import OrderItem from '../components/OrderItem';
import { useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect, useDispatch } from 'react-redux';
import cartsActions from '../store/carts/actions';
import storage from '../storage/Storage';

function OrderSummary(props) {
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const [overallTotal, setOverallTotal] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [gst, setGst] = useState(0);

    useEffect(() => {
        dispatch(cartsActions.getCartDetails());
    }, []);

    useEffect(() => {
        console.log('Cart is updated = ', props.carts.details);
        let overallTotal = 0;
        for (const menu of props.carts.details) {
            overallTotal += totalMenuPrice(parseFloat(menu.price), menu.qty, menu.extra);
        }
        const gstAmount = overallTotal * 0.15;
    
        setOverallTotal(overallTotal);
        setGst(gstAmount);
        setSubTotal(overallTotal - gstAmount);

    }, [props.carts.details]);

    const goOrderConfirm = () => {
        if(props.carts.details.length > 0){
            storage.setOverallTotal(overallTotal);
            navigate("/orderConfirm");
        }
    };

    const emptyCart = (c) => {
        if(typeof c === 'undefined' || c.length == 0){
            console.log("cart is now 0 items", c);
            navigate(-1);
        }
    };

    const onCancel = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div style={{
                        backgroundColor: "#322923",
                        color: "#fff",
                        padding: '20px',
                        borderRadius: '10px'
                    }}>
                        <h5>{translate("Are you sure you want to cancel?")}</h5>
                        <p>{translate("This action will empty your cart!")}</p>
                        <div style={{display: 'flex'}}>
                            <div style={{
                                backgroundColor: "#fff",
                                color: "#333",
                                borderRadius: '5px',
                                padding: "5px",
                                width: '50%',
                                cursor: 'pointer',
                                textAlign: 'center',
                                marginRight: '5px'
                            }} onClick={onClose}>
                                {translate('No')}
                            </div>
                            <div style={{
                                backgroundColor: "#df2351",
                                color: "#fff",
                                borderRadius: '5px',
                                padding: "5px",
                                width: '50%',
                                cursor: 'pointer',
                                paddingInline: "10px",
                                marginLeft: '5px',
                                textAlign: 'center'
                            }} 
                            onClick={() => {
                                onClose();
                                dispatch(cartsActions.clearCart());
                                navigate("/");
                            }}>
                                {translate('Yes, Cancel!')}
                            </div>
                        </div>
                    </div>
                );
            },
        });
    };

    const onPlus = (id) => {
        let filter = props.carts.details.filter(item => {
            return item.id === id;
        });
        if (filter.length > 0) {
            let item = filter[0];
            item.qty = item.qty + 1;

            let updatedCart = props.carts.details.filter(item => {
                return item.id !== id;
            });
            
            updatedCart.push(item);
            dispatch(cartsActions.updateCart(updatedCart));
        }
    }

    const onMinus = (id) => {
        let filter = props.carts.details.filter(item => {
            return item.id === id;
        });
        if (filter.length > 0) {
            let item = filter[0];
            item.qty = item.qty - 1;

            let updatedCart = props.carts.details.filter(item => {
                return item.id !== id;
            });

            //remove from cart if qty == 0
            if (item.qty > 0) {
                updatedCart.push(item);
            }
            dispatch(cartsActions.updateCart(updatedCart));
            emptyCart(updatedCart);
        }
    }

    const onDelete = (id) => {
        console.log('onDelete ', id);
        let updatedCart = props.carts.details.filter(item => {
            return item.id !== id;
        });
        
        console.log('updatedCarte ', updatedCart);
        dispatch(cartsActions.updateCart(updatedCart));
        emptyCart(updatedCart);
    }  

    const sortedCart = props.carts.details.sort(function (a, b) {
        console.log("props details", props.carts.details);
        return new Date(a.createdAt) - new Date(b.createdAt);
    });
    console.log("value of sorted cart", sortedCart);

    return(
         <MainContainer
            title='Back to Menu'
            isHome={false}
            hideMenu={true}
            hideLogo={false}
            imagePath={null}>
              <Center>
                  <Box bg="#FFFFFF" padding={4} height='100%' width='98%' borderRadius={10}>
                        <Box mb={4}>
                            <Heading style={styles.content} fontWeight="bold"> Order Summary </Heading>
                        </Box>
                        <Center>
                            {sortedCart.map((menu, index) => {
                                return (
                                    <OrderItem
                                        key={menu.id}
                                        index={index}
                                        menu={menu}
                                        onPlus={() => onPlus(menu.id)}
                                        onMinus={() => onMinus(menu.id)}
                                        onDeleteItem={() => onDelete(menu.id)}
                                    />
                                );
                            })}

                            {sortedCart.length > 0 ? (
                                <Box width="95%" paddingRight={4} style={{borderTopWidth: 2, borderTopColor: "#322923"}}>
                                    <Box>
                                        <HStack alignItems="center" justifyContent="space-between" my={2}>
                                            <Box><Text color="#322923" fontSize="md">Sub Total</Text></Box>
                                            <Box><Text color="#322923" fontSize="md" fontWeight="bold">${parseFloat(subTotal).toFixed(2)}</Text></Box>
                                        </HStack>
                                    </Box>
                                    <Box>
                                        <HStack alignItems="center" justifyContent="space-between" my={2}>
                                            <Box><Text color="#322923" fontSize="md">GST</Text></Box>
                                            <Box><Text color="#322923" fontSize="md" fontWeight="bold">${parseFloat(gst).toFixed(2)}</Text></Box>
                                        </HStack>
                                    </Box>
                                    <Box>
                                        <HStack alignItems="center" justifyContent="space-between" my={2}>
                                            <Box><Text color="#322923" fontSize="xl" fontWeight="bold">Total</Text></Box>
                                            <Box><Text color="#322923" fontSize="md" fontWeight="bold">${parseFloat(overallTotal).toFixed(2)}</Text></Box>
                                        </HStack>
                                    </Box>
                                    <Box>
                                    <HStack paddingTop={10} alignItems="center" justifyContent="space-evenly" my={2}>
                                        <Button borderRadius="20" height="8" width="50%" top={0} bg="#4d3728" onPress={goOrderConfirm} colorScheme="transparent">
                                            <Text style={styles.order} >{translate('Place Order')}</Text>
                                        </Button>
                                        <Button borderRadius="20" height="8" width="50%" top={0} bg="light.100" onPress={onCancel} colorScheme="transparent">
                                            <Text  style={styles.title}>{translate('Cancel')}</Text>
                                        </Button>
                                    </HStack>
                                    </Box>
                                </Box>
                            ) : (
                                <Box width="95%" paddingRight={4}>
                                    <Text  style={styles.title}>{translate('Please wait...')}</Text>
                                </Box>
                            )}
                           
                        </Center>
                  </Box>
              </Center>
        </MainContainer>
    );
}

const styles = StyleSheet.create({
    menuContainer: {
        marginBottom: 100
    },
    footer: {
        backgroundColor: '#2b3036',
        width: '100%',
        fontStyle: "Helvetica-NeueLT",
        fontSize:10,
        fontWeight:"normal",
        color:"#f5f5f4"
    },
    gridContainer:{
        backgroundColor: '#46362a',
        width: '100%',
    },
    heading:{
        fontStyle: "Helvetica-NeueLT",
        fontSize:25,
        fontWeight:"normal",
        color:"#f5f5f4",
        width:230
    },
    title:{
        fontStyle: "Helvetica-NeueLT",
        fontSize:15,
        fontWeight:"normal",
        color:"#4d3728",
        mt:10,
        ml:7,
    },
    mainContainer:{
        height: 156,
    },
    content: {
        fontStyle: "Helvetica-NeueLT",
        fontSize:25,
        fontWeight:"normal",
        color:"#4d3728",
        width:195,
    },
    tableNumber:{
        fontStyle: "Helvetica-NeueLT",
        fontSize:25,
        fontWeight:"normal",
        color:"#4d3728",
        width:195,
        position:"absolute",
        top:-35,
        right:0,
    },
    order: {
        color: '#ffffff',
        fontStyle: "Helvetica-NeueLT",
        fontSize:15,
        fontWeight:"normal",
    },
    cursor: 'pointer',
});

function mapStateToProps(state) {
    return {
        carts: state.carts,
    }
  }
  
export default connect(mapStateToProps)(OrderSummary);