import React, {useEffect, useState} from 'react';
import { StyleSheet, BackHandler, } from 'react-native';
import { useNavigate } from "react-router-dom";
import { Stack, Text, Heading, Center, Button} from 'native-base';
import { MaterialCommunityIcons } from "@expo/vector-icons";

import '../../styles/styles.css';
import MainContainer from '../layouts/Container';
import {translate} from '../helpers';
import storage from '../storage/Storage';

export default function ThankYouDemo() {
    MaterialCommunityIcons.loadFont();
    const [selected, setSelected] = React.useState(1);
    const [isEnabled, setIsEnabled] = useState(false);
    const [prepTime, setPreptime] = useState('loading...');
    const [orderNumber, setOrderNumber] = useState('loading...');
    const [orderId, setOrderId] = useState();
    const toggleSwitch = () => setIsEnabled(previousState => !previousState);

    let navigate = useNavigate();
    const goMenuItem = () => {
        navigate("/menuItem");
    };

    const goOrderConfirm = () => {
        navigate("/orderConfirm");
    };

    const goHome = () => {
        navigate("/");
    };

     useEffect( () => {
       
         async function  updateOrder() {
            // const orderid = await storage.getOrderId();
            // //const paymentid = await storage.getPaymentIntentId();
            // let payload = {
            //     "order_id": orderid,
            //     "status": "stripe",
            // };
    
            // console.log("params used", payload);
    
            // //let response = await apiClient.updatePaymentStatus(payload);
            // console.log("response from payment", response);
            

            const ordernum =  await storage.getOrderNumber();
            const preptime =  await storage.getPrepTime();
            setOrderNumber(ordernum);
            setPreptime(preptime);
            const cartVal = storage.clearCart();
            console.log("Cart deleted from local storage", cartVal);
        }
        updateOrder();
        const backHandler = BackHandler.addEventListener('hardwareBackPress', () => true)
        return () => backHandler.remove()
     }, []);

    return(
         <MainContainer
            title='Success!'
            isHome={true}
            hideMenu={false}
            imagePath={require('../assets/header.jpg')}>
              <Center>
                <Stack>
                    <Center><Heading style={styles.contentHeader}> {translate('Thanks for your order!')} </Heading></Center>
                </Stack>
                <Stack mt={15} >
                    <Heading style={styles.order}> {translate('Your order number is:')} </Heading>
                    <Heading style={styles.order}> {translate(orderNumber)}</Heading>
                </Stack>
                <Stack mt={20}>
                    <Heading style={styles.order}> {translate('Your order should arrive in')}</Heading>
                    <Heading style={styles.order}> {translate(prepTime + ' minutes')}</Heading>
                </Stack>
                 <Stack mt={45}>
                     <Button borderRadius="20" height="8" width="185" top={0} bg="light.100" onPress={goHome} colorScheme="transparent">
                     <Text  style={styles.title}>{translate('Menu')}</Text>
                     </Button>
                 </Stack>
            </Center>
        </MainContainer>
    );
}

const styles = StyleSheet.create({
    menuContainer: {
        marginBottom: 100
    },
    footer: {
        backgroundColor: '#2b3036',
        width: '100%',
        fontStyle: "Helvetica-NeueLT",
        fontSize:10,
        fontWeight:"normal",
        color:"#f5f5f4"
    },

    heading:{
        fontStyle: "Helvetica-NeueLT",
        fontSize:25,
        fontWeight:"normal",
        color:"#f5f5f4",
        width:230
    },
    title:{
        fontStyle: "Helvetica-NeueLT",
        fontSize:15,
        fontWeight:"normal",
        color:"#4d3728",
        mt:10,
        ml:7,
    },
    mainContainer:{
        backgroundColor: '#46362a',
        height: 156,
    },
    contentHeader: {
        fontStyle: "Helvetica-NeueLT",
        fontSize:30,
        fontWeight:"normal",
        color:"#f5f5f4",
        size:"md",
    },
    content: {
        fontStyle: "Helvetica-NeueLT",
        fontSize:20,
        fontWeight:"normal",
        width:275,
        color:"#f5f5f4",
    },
    order: {
        color: '#ffffff',
        fontStyle: "Helvetica-NeueLT",
        fontSize:20,
        fontWeight:"normal",
        size:"md",
        textAlign: 'center'
    },
    cursor: 'pointer',
});