import constants from "../constants";
import { put, takeEvery, all, call } from 'redux-saga/effects';
import api from '../../webservice/Client';

function* getMenuList({payload}) {
    try {
        //
    } catch(e) {
        //
    }
}

function* getUncategorizedMenus({payload}) {
    try {
        yield put({ type: constants.MENU_SET_LOADING, payload: true });
        const response = yield call(api.getUncategorizedMenus);
        yield put({ type: constants.MENU_SET_UNCATEGORIZED_MENUS, payload: response });
        yield put({ type: constants.MENU_SET_LOADING, payload: false });
    } catch(e) {
        yield put({ type: constants.MENU_SET_LOADING, payload: false });
    }
}

export default function* () {
    yield all([
        takeEvery(constants.MENU_GET_MENUS, getMenuList),
        takeEvery(constants.MENU_GET_UNCATEGORIZED_MENUS, getUncategorizedMenus)
    ]);
}
