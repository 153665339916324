import React, {useEffect, useState} from 'react';
import { StyleSheet, ScrollView, Switch } from 'react-native';
import { Navigate, useNavigate } from "react-router-dom";
import { Card,Stack,Box,Pressable, Select, ZStack,HStack, Text, Heading, Image, Center, Icon, Button} from 'native-base';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { ParallaxBanner, ParallaxProvider} from 'react-scroll-parallax';

import '../../styles/styles.css';
import MainContainer from '../layouts/Container';
import {translate} from '../helpers';

export default function PaymentFailure() {
    MaterialCommunityIcons.loadFont();
    const [selected, setSelected] = React.useState(1);
    const [isEnabled, setIsEnabled] = useState(false);
    const [prepTime, setPreptime] = useState();
    const [orderNumber, setOrderNumber] = useState();
    const [orderId, setOrderId] = useState();
    const toggleSwitch = () => setIsEnabled(previousState => !previousState);

    let navigate = useNavigate();
    const goMenuItem = () => {
        navigate("/menuItem");
    };

    const goOrderConfirm = () => {
        navigate("/orderConfirm");
    };

    const goHome = () => {
        navigate("/");
    };

    return(
         <MainContainer
            title='Fail!'
            isHome={false}
            hideMenu={true}
            imagePath={require('../assets/header.jpg')}>
              <Center>
                <Stack>
                    <Center><Heading style={styles.contentHeader}> {translate('Your payment has failed')} </Heading></Center>
                </Stack>
                <Stack mt={15} >
                    <Heading style={styles.order}> {translate('Please contact support for this issue.')} </Heading>
                </Stack>
                <Stack mt={45}>
                     <Button borderRadius="20" height="8" width="185" top={0} bg="light.100" onPress={goHome} colorScheme="transparent">
                     <Text  style={styles.title}>{translate('Menu')}</Text>
                     </Button>
                 </Stack>
            </Center>
        </MainContainer>
    );
}

const styles = StyleSheet.create({
    menuContainer: {
        marginBottom: 100
    },
    footer: {
        backgroundColor: '#2b3036',
        width: '100%',
        fontStyle: "Helvetica-NeueLT",
        fontSize:10,
        fontWeight:"normal",
        color:"#f5f5f4"
    },

    heading:{
        fontStyle: "Helvetica-NeueLT",
        fontSize:25,
        fontWeight:"normal",
        color:"#f5f5f4",
        width:230
    },
    title:{
        fontStyle: "Helvetica-NeueLT",
        fontSize:15,
        fontWeight:"normal",
        color:"#4d3728",
        mt:10,
        ml:7,
    },
    mainContainer:{
        backgroundColor: '#46362a',
        height: 156,
    },
    contentHeader: {
        fontStyle: "Helvetica-NeueLT",
        fontSize:30,
        fontWeight:"normal",
        color:"#f5f5f4",
        size:"md",
    },
    content: {
        fontStyle: "Helvetica-NeueLT",
        fontSize:20,
        fontWeight:"normal",
        width:275,
        color:"#f5f5f4",
    },
    order: {
        color: '#ffffff',
        fontStyle: "Helvetica-NeueLT",
        fontSize:20,
        fontWeight:"normal",
        size:"md",
        textAlign: 'center'
    },
    cursor: 'pointer',
});