import React, {useEffect, useState, useRef} from 'react';
import {Dimensions, View, TextInput} from 'react-native';
import { Radio, Checkbox, Image, Select, HStack, VStack, Pressable, Box, Circle, Text, Center, Icon, Button, Heading} from 'native-base';
import { useSpring, animated } from '@react-spring/web';
import {translate, totalMenuPrice} from '../helpers';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import store from '../store';
import cartsActions from '../store/carts/actions';
import {AccordionList, Collapse, CollapseHeader, CollapseBody} from "accordion-collapse-react-native";

const AnimatedBox = animated(Box);
const AnimatedVStack = animated(VStack);
const AnimatedHStack = animated(HStack);
const AnimatedImage = animated(Image);

export default function MenuItem({
    menu,
    originalPrice,
    itemInCartQty,
    onRef,
    onConfirm,
    selected,
    onPress,
}) {
    const [price, setPrice] = useState(menu.price);
    const [quantity, setQuantity] = useState(0);
    const [extras, setExtras] = useState([]);
    const [enableNotes, setEnableNotes] = useState(menu.enable_notes === 1 ? true : false);
    const [notes, setNotes] =useState('');
    const [expandedOptions, setExpandedOptions] = useState([0]);
    const [expandedIngredients, setExpandedIngredients] = useState(true);

    const extrasRef = React.useRef(extras);

    const nodeRef = useRef();

    const isMobile = window.outerWidth <= 768;
    const deviceWidth = isMobile ? window.outerWidth : 445;
    const fullImageW = Math.round(deviceWidth * 0.9);

    const [{ width, borderBottomRadius, opacity1, opacity2, displaySmallDesc, displayLongDesc }, setSelectedProps] = useSpring(() => ({
        displaySmallDesc: 'flex',
        width: 150,
        borderBottomRadius: 10,
        opacity1: 1,
        opacity2: 0,
        displayLongDesc: 'none',
    }));

    useEffect(() => {
        if (selected) {
            setSelectedProps({
                displaySmallDesc: 'flex',
                width: fullImageW,
                borderBottomRadius: 0,
                opacity1: 0,
                opacity2: 1,
                displayLongDesc: 'flex',
                config: { duration: 200 },
                onRest: () => {
                    nodeRef.current.measure( (fx, fy, width, height, px, py) => {
                        window.scrollTo(0, fy);
                    })
                }
            });
        } else {
            setSelectedProps({
                displaySmallDesc: 'flex',
                width: 150,
                borderBottomRadius: 10,
                opacity1: 1,
                opacity2: 0,
                displayLongDesc: 'none',
                config: { duration: 100 },
            });
        }
    }, [selected]);

    /**
     * Re calculate price when there are changes on
     * Quantity or Extras
     */
    useEffect(() => {
        let qty = quantity === 0 ? 1 : quantity;
        let price = totalMenuPrice(parseFloat(menu.price), qty, extras);
        setPrice(price);
    }, [quantity, extras]);

    const onExpand = () => {
        if(menu.status === 'unavailable') return;
        onPress(menu);
    }

    const reset = () => {
        setQuantity(0);
        extrasRef.current = [];
        setExtras([]);
        setPrice(menu.price);
    }

    const onCancelled = () => {
        reset();
        onPress(null);
    }

    function makeId(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
       }
       return result;
    }

    const onAddedToOrder = () => {
        if(quantity > 0){
            setSelectedProps({
                displaySmallDesc: 'flex',
                width: 150,
                borderBottomRadius: 10,
                opacity1: 1,
                opacity2: 0,
                displayLongDesc: 'none',
                config: { duration: 80 },
                onRest: () => {
                    let items = {
                        id: makeId(8),
                        menu_item_id: menu.id,
                        menu_name: menu.name,
                        qty:  quantity,
                        price: menu.price,
                        extra: extras,
                        notes: notes,
                        createdAt: new Date(),
                        onFinished: () => {}
                    }
                    //onConfirm(items);
                    console.log("value of items from menuitem", items);
                    store.dispatch(cartsActions.addToCart(items));
                    reset();
                }
            });
        }
    }

    const onChangeOptions = (option, choice, value) => {
        if (quantity === 0) {
            setQuantity(quantity + 1);
        }

        const isMultiple = parseInt(option.select_num) > 1;

        let newExtras = extrasRef.current.filter(item => {
            return item.option_id !== option.id;
        });

        if((isMultiple && value.length > 0) || (!isMultiple && value > -1)) {
            let items = [];
               
            if(isMultiple) {
                value.forEach(index => {
                    const item = option.items[index];
                    items.push({
                        name: item.name,
                        price: item.price,
                        expense: item.cost
                    });
                })
            } else {
                const item = option.items[value];
                items.push({
                    name: item.name,
                    price: item.price,
                    expense: item.cost
                });
            }
    
            newExtras.push({
                option_id: option.id,
                type: option.type,
                items
            });
        }

        extrasRef.current = newExtras;
        setExtras(newExtras);
    }

    const onChangeComboOptions = (option, choice, value) => {
        if (quantity === 0) {
            setQuantity(quantity + 1);
        }

        const isMultiple = parseInt(choice.select_num)  > 1;

        let newExtras = extrasRef.current.filter(item => {
            return item.option_id !== option.id;
        });

        const currentExtraFilter = extrasRef.current.filter(item => {
            return item.option_id === option.id;
        });
        let currentExtra = currentExtraFilter.length > 0 ? currentExtraFilter[0] : null;

        let items = currentExtra ? {...currentExtra?.items} : {};
        let choices = [];
            
        if(isMultiple) {
            if(value.length === 0) {
                delete items[choice.name];
            } else {
                value.forEach(index => {
                    const item = choice.items[index];
                    choices.push({
                        name: item.name,
                        price: item.price,
                        expense: item.cost
                    });
                    items[choice.name] = choices;
                })
            }
        } else {
            const item = choice.items[value];
            choices.push({
                name: item.name,
                price: item.price,
                expense: item.cost
            });
            items[choice.name] = choices;
        }

        newExtras.push({
            option_id: option.id,
            type: option.type,
            items
        });

        extrasRef.current = newExtras;
        setExtras(newExtras);
    }

    const onPlus= async ()=> {
        const qty = quantity + 1;
        setQuantity(qty);
    }

    const onMinus = async ()=> {
        const qty = quantity - 1 < 0 ? 0 : quantity - 1;
        setQuantity(qty);
    }

    const isAvailable = menu.status === 'available';

    const dietaryInfo = [];// menu.dietary_info && menu.dietary_info.length > 2 && !selected ? menu.dietary_info.slice(0, 2) : menu.dietary_info;

    const onToggleOption = (index, isExpanded) => {
        if(isExpanded) {
            if(!expandedOptions.includes(index)) {
                setExpandedOptions(prev => [...prev, index]);
            }
        } else {
            if(expandedOptions.includes(index)) {
                let expanded = expandedOptions.filter(val => val !== index);
                setExpandedOptions(expanded);
            }
        }
    }

    const customSortOrder = ['combo', 'variation', 'add-on', 'dietary'];
    menu.new_options.sort((a, b) => {
        const typeA = customSortOrder.indexOf(a.type);
        const typeB = customSortOrder.indexOf(b.type);
        return typeA - typeB;
    });

    let isValid = false;

    const comboFilter = menu.new_options.find(option => option.type === 'combo');
    if (comboFilter) {
        const optionComboFilter = extrasRef.current.find(option => option.type === 'combo');
        const optionComboLength = optionComboFilter ? Object.keys(optionComboFilter.items).length : 0;

        isValid = comboFilter.items.length === optionComboLength;
    } else {
        isValid = quantity > 0;
    }

    return(
        <Box width="95%" mb={8}  ref={nodeRef} >
            <Pressable onPress={onExpand} style={{cursor: 'pointer'}}>
                <AnimatedHStack bg="#322923"
                    style={{
                        borderRadius: 10,
                        borderBottomRightRadius: borderBottomRadius,
                        borderBottomLeftRadius: borderBottomRadius
                    }}
                >   
                    {!isAvailable && (
                        <Box style={{
                            position: 'absolute',
                            top: 55,
                            left: 15,
                        }}>
                            <Text lineHeight="xs" color="#eae5da" fontSize="xl" fontWeight={500}>Not Available</Text>
                        </Box>
                    )}

                    <Text></Text>
                    <animated.img
                        src={menu.photo ? menu.photo : require('../assets/placeholder.jpg')}
                        height={150}
                        style={{
                            width,
                            position: 'absolute',
                            borderRadius: 10,
                            borderBottomRightRadius: borderBottomRadius,
                            borderBottomLeftRadius: borderBottomRadius,
                            cursor: 'pointer',
                            objectFit: 'cover',
                            opacity: isAvailable ? 1 : 0.2
                        }}
                    />

                     <AnimatedHStack space={2} position="absolute" bottom={0} left={4} mb={2} style={{
                        opacity: isAvailable ? 1 : 0.5,
                        alignItems: 'center'
                    }}>
                        {dietaryInfo.map((dietInfo, index) => {
                            return (
                                <Circle key={dietInfo.short} size="30px" bg='#707070'>
                                    <Text color="#fff" fontSize="sm">{dietInfo.short}</Text>
                                </Circle>
                            )
                        })}

                        {!selected && menu.dietary_info.length > 2 && (
                            <Circle size="25px" bg='#707070'>
                                <Text color="#fff" fontSize="sm">{menu.dietary_info.length - 2}+</Text>
                            </Circle>
                        )}
                    </AnimatedHStack>


                    {itemInCartQty > 0 && (
                        <Circle size="23px" position="absolute" top={-4} left={-4} style={{backgroundColor: '#df2351'}}>
                            <Text color="#fff" fontSize="sm">{itemInCartQty}</Text>
                        </Circle>
                    )}

                    <AnimatedBox height={150} flex={1} px={4} style={{
                        display: displaySmallDesc,
                        opacity: opacity1,
                        marginLeft: 150,
                        paddingVertical: 10,
                    }}>
                        <Box height="80%" style={{opacity: isAvailable ? 1 : 0.2}}>
                            <Text lineHeight="xs" color="#eae5da" fontSize="xl">{menu.name}</Text>
                            <Text lineHeight="xs" mt={2} isTruncated numberOfLines={2} color="#707070" fontSize="sm" fontWeight="light">
                                {(menu.description && menu.description !== 'null') ? menu.description : ''}
                            </Text>
                        </Box>
                        <Box height="20%" justifyContent="end" style={{opacity: isAvailable ? 1 : 0.2}}>
                            <Text color="#eae5da" fontSize="lg" fontWeight="bold">
                                ${parseFloat(price).toFixed(2)}{' '}
                                {typeof originalPrice !== 'undefined' && (
                                    <Text strikeThrough color="#aaa" fontSize="sm" fontWeight="normal">${originalPrice}</Text>
                                )}
                            </Text>
                        </Box>
                    </AnimatedBox>
                </AnimatedHStack>
            </Pressable>

            <AnimatedVStack style={{display: displayLongDesc, opacity: opacity2}} roundedBottom="lg" bg="#fffbf2">
                <Box>
                    <Box p={4}>
                        <Text color="#5a3b28" fontSize="xl" fontWeight="bold">{menu.name}</Text>
                        <Text color="#707070" fontSize="sm" fontWeight="light">
                        {(menu.description && menu.description !== 'null') ? menu.description : ''}
                        </Text>
                    </Box>

                    {(menu.new_options.length > 0 || menu.enable_ingredients === 1) && (
                        <Box mt={4}>
                            {menu.enable_ingredients === 1 && menu.ingredients.filter(ingredient => ingredient.status === 'unavailable').length > 0 && (
                                <>
                                    <Box mb={1} paddingX={5} paddingY={1} background='#f5ece1' flexDirection="row" alignItems="center" justifyContent="space-between">
                                        <Text fontWeight={"bold"}>Unavailable Ingredients</Text>
                                    </Box>
                                    <Box mb={4} style={{paddingHorizontal:20}}>
                                        {menu.ingredients.filter(ingredient => ingredient.status === 'unavailable').map((ingredient, index) => {
                                            return (
                                                <Text key={`row${index}`} fontSize={14} color="#aaa">{ingredient.name}</Text>
                                            )
                                        })}
                                    </Box>
                                </>
                            )}

                            {menu.new_options.map((option, optIndex) => {
                                const filter = extras.filter(item => item.option_id === option.id);
                                const extra = filter.length > 0 ? filter[0] : null;
                                const optionItems = extra ? extra.items : [];
                                const totalSelected = optionItems.length;
                                return (
                                    <Collapse
                                        isExpanded={expandedOptions.includes(optIndex)}
                                        key={option.id}
                                        onToggle={(isExpanded) => onToggleOption(optIndex, isExpanded)}>
                                        <CollapseHeader>
                                            <Box mb={1} paddingX={5} paddingY={1} background='#f5ece1' flexDirection="row" alignItems="center" justifyContent="space-between">
                                                <Text fontWeight={"bold"}>{option.name}</Text>
                                                <MaterialCommunityIcons name={expandedOptions.includes(optIndex) ? "chevron-up" : "chevron-down"} size={20} />
                                            </Box>
                                        </CollapseHeader>
                                        <CollapseBody>
                                            <Box mb={3} style={{paddingHorizontal:20, paddingVertical: 5}}>
                                                {option.type !== 'combo' ? (
                                                    <>
                                                        <Text mb={3} fontSize={11} fontStyle="italic" fontWeight="semibold" color="#a8a29e">Optional. Choose max {option.select_num}</Text>
                                                        <OptionForm
                                                            option={option}
                                                            totalSelected={totalSelected}
                                                            onChangeOptions={onChangeOptions} />
                                                    </>
                                                ) : (
                                                    <>
                                                        {option.items.map((choice, i) => {
                                                            const choices = extra ? extra.items : null;
                                                            const choiceItems = choices && choices[choice.name] ? choices[choice.name] : [];
                                                            const totalSelChoices = choiceItems.length;
                                                            return (
                                                                <Box key={`row${i}`} mb={3}>
                                                                    <Text fontWeight="bold">{choice.name}</Text>
                                                                    <Text mb={3} fontSize={11} fontStyle="italic" fontWeight="semibold" color="#a8a29e">Required. Choose max {choice.select_num}</Text>
                                                                    <OptionForm
                                                                        option={option}
                                                                        choice={{id: i, ...choice}}
                                                                        totalSelected={totalSelChoices}
                                                                        onChangeOptions={onChangeComboOptions} />
                                                                </Box>
                                                            )
                                                        })}
                                                    </>
                                                )}
                                            </Box>
                                        </CollapseBody>
                                    </Collapse>
                                )
                            })}
                        </Box>
                    )}

                    <Box>
                        {enableNotes === true && (
                            <HStack mx={4} alignItems="center" space={2} >
                                <Box mt={4} width="100%">
                                    <Text color="#5a3b28" fontSize="l" fontWeight="bold">
                                    Notes:
                                    </Text>
                                    <TextInput
                                        value={notes}
                                        placeholder="Enter notes"
                                        multiline = {true}
                                        numberOfLines={3}
                                        style={{
                                                height:60,
                                                textAlignVertical: 'top',
                                                width:'100%',
                                                borderWidth: 1,
                                                borderColor: '#aaa',
                                                borderRadius: 5,
                                                paddingVertical: 4,
                                                paddingHorizontal: 8
                                        }}
                                        onChangeText={setNotes}
                                    />
                                </Box>
                            </HStack>
                        )}

                        <HStack p={4} alignItems="center" justifyContent="space-between">
                            <Box>
                                <Text color="#5a3b28" fontSize="xl" fontWeight="bold">
                                    ${parseFloat(price).toFixed(2)}
                                </Text>
                            </Box>
                            <HStack space={2}>
                                {quantity > 0 && (
                                    <Pressable onPress={onMinus} style={{cursor: 'pointer'}}>
                                        <Circle size="30px" bg='#707070'>
                                            <MaterialCommunityIcons color="#fff" name="minus" size={20} />
                                        </Circle>
                                    </Pressable>
                                )}
                                <Box><Text color="#5a3b28" fontSize="xl" fontWeight="bold">{quantity > 0 ? quantity : ''}</Text></Box>
                                <Pressable onPress={onPlus} style={{cursor: 'pointer'}}>
                                    <Circle size="30px" bg='#707070'>
                                        <MaterialCommunityIcons color="#fff" name="plus" size={20} />
                                    </Circle>
                                </Pressable>
                            </HStack>
                        </HStack>
                        <HStack my={2} space={2} justifyContent="center">
                            <Button
                                onPress={onAddedToOrder}
                                width={100}
                                bg={isValid ? "#5a3b28" : "#aaa"}
                                disabled={!isValid}
                                style={{height: 35, borderRadius: 17.5,  cursor: 'pointer'}}
                            >
                                <Text color="#fff" fontSize="sm" fontWeight="bold">{translate('Add To Cart')}</Text>
                            </Button>
                            <Button onPress={onCancelled} width={100} bg="#eae5da" style={{height: 35, borderRadius: 17.5, cursor: 'pointer',}}>
                                <Text color="#707070" fontSize="sm" fontWeight="bold">{translate('Cancel')}</Text>
                            </Button>
                        </HStack>
                    </Box>
                </Box>
            </AnimatedVStack>

        </Box>
    );
};

const OptionForm = (props) => {
    const [checkboxSelected, setCheckboxSelected] = useState({});

    const id = props.option.type === 'combo' ? props.choice.id : props.option.id;
    const items = props.option.type === 'combo' ? props.choice.items : props.option.items;
    const selectNum = props.option.type === 'combo' ? props.choice.select_num : props.option.select_num;
    const isMultiple = parseInt(selectNum) > 1;

    const onChangeOptions = (values) => {
        setCheckboxSelected(prev => ({...prev, [`option${id}`]: isMultiple ? values : [values]}));
        props.onChangeOptions(props.option, props.choice, values);
    }

    if (isMultiple) {
       return (
            <Checkbox.Group
                value={checkboxSelected[`option${id}`] || []}
                name={`option${id}`}
                onChange={onChangeOptions}>
                {items.map((item, i) => {
                    const itemsSelected = checkboxSelected[`option${id}`] || [];
                    const notSelected = !itemsSelected.includes(i);
                    return (
                        <Box key={`itemRow${i}`} my={1} width="100%" flexDirection="row" alignItems="center" justifyContent="space-between">
                            <Checkbox                                
                                size="sm"
                                colorScheme="amber"
                                value={i}
                                isDisabled={item.status === 'unavailable' || (props.totalSelected >= parseInt(selectNum) && notSelected)}>
                                <Text fontSize={14} color={item.status === 'unavailable' ? "#aaa" : "#000"}>
                                    {item.name} {item.status === 'unavailable' ? "(Unavailable)" : ''}
                                </Text>
                            </Checkbox>
                            {parseFloat(item.price) > 0 && (
                                <Text fontWeight="semibold" color={item.status === 'unavailable' ? "#aaa" : "#000"}>+ ${item.price}</Text>
                            )}
                        </Box>
                    );
                })}
            </Checkbox.Group>
       )
    }

    return (
        <Radio.Group
            name={`option${id}`}
            value={checkboxSelected[`option${id}`] ? checkboxSelected[`option${id}`][0] : null}
            onChange={onChangeOptions}>
                {items.map((item, i) => {
                    return (
                        <Box key={`itemRow${i}`} my={1} width="100%" flexDirection="row" alignItems="center" justifyContent="space-between">
                            <Radio
                                size="sm"
                                colorScheme="amber"
                                value={i}
                                isDisabled={item.status === 'unavailable'}>
                                <Text fontSize={14} color={item.status === 'unavailable' ? "#aaa" : "#000"}>
                                    {item.name} {item.status === 'unavailable' ? "(Unavailable)" : ""}
                                </Text>
                            </Radio>
                            {parseFloat(item.price) > 0 && (
                                <Text fontWeight="semibold" color={item.status === 'unavailable' ? "#aaa" : "#000"}>+ ${item.price}</Text>
                            )}
                        </Box>
                    )
                })}
        </Radio.Group>
    );
}