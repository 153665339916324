import initialState from './states';
import constants from '../constants';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.PAYMENT_SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        case constants.PAYMENT_SET_DETAILS:
            return {
                ...state,
                details: action.payload,
            }
        default:
            return state;
    }
};

