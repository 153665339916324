import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { connect, useDispatch } from 'react-redux';
import paymentsActions from '../store/payments/actions';
import PaymentGooglePay from './PaymentGooglePay';

function PaymentSetup(props) {
    const stripePromise = loadStripe('pk_test_51M0SIAFyGkgGff01DDo12L9QcP2HLOzsBWi7sIocfYupZg5F2OCfa10XJoxAIkpsUQBeGLfolmvbP4O56sBnSaIT00Cch6e0xB', {
        stripeAccount: props.restaurant.restaurant.stripe_account_id
    });
    
    let navigate = useNavigate();
    let dispatch = useDispatch();
       
    useEffect(() => {
        console.log('restaurant', props.restaurant);
        if(props.orders.orderConfirmationDetails) {
            let payload = {
                "restaurant_id": props.restaurant.restaurant.id,
                "amount": parseInt(props.orders.orderConfirmationDetails.gross_price * 100), //multiplied by 100 coz stripe accepts smallest currency unit which is cents
                "currency": "nzd", //default
                "method": "card",
                onError: () => {
                    navigate("/fail");
                }
            };
            console.log('getPaymentDetails payload', payload);
            dispatch(paymentsActions.getPaymentDetails(payload));
        }
    }, []);

    const appearance = {
        theme: 'stripe',
        variables: {
            colorPrimary: '#322923',
            colorBackground: '#322923',
            colorText: '#ffffff',
            colorDanger: '#ffffff',
            fontFamily: 'Ideal Sans, system-ui, sans-serif',
            spacingUnit: '2px',
            borderRadius: '4px',
            colorPrimaryText:'#ffffff'
            // See all possible variables below
        }
    };
    
    const options = {
        clientSecret: props.payments.details?.client_secret,
        appearance,
    };

    return (
        <div>
            {props.payments.details?.client_secret && (
                <Elements stripe={stripePromise} options={options}>
                    <CheckoutForm />
                </Elements>
            )}
        </div>
    )
}


function mapStateToProps(state) {
    return {
        orders: state.orders,
        payments: state.payments,
        restaurant: state.restaurant
    }
  }
  
export default connect(mapStateToProps)(PaymentSetup);