export default {
    loading: false,
    restaurant: null,
    branch: null,
    settings:{
        staff_unavailable: false,
        payment_methods: {
            pay_in_till: true,
            card: true,
        },
        takeaway_payment_methods: {
            pay_in_till: true,
            card: true,
        }
    },
};