// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../lib/node_modules/expo-cli/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../lib/node_modules/expo-cli/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../src/assets/fonts/Helvetica Neue LTW0697BlkCnObl/Helvetica Neue LTW0697BlkCnObl.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "html {\n    background-color: #46362a;\n}\nbody {\n    background-color: #46362a;\n    color: white;\n    font-family: Helvetica;\n    text-align: center;\n}\n.imgHero{\n    display: inline;\n    margin: 0 auto;\n    height: 100%;\n    width: auto;\n    position:relative;\n}\n\n.logo{\n    display: inline;\n    margin: 0 auto;\n    height: 180px;\n    width: 270px;\n    top:-220px;\n    position:relative;\n}\n\n.image-cropper {\n    width: 600px;\n    height: 300px;\n    top:-370px;\n    position: relative;\n    width:auto;\n}\n\n.menu{\n    margin: 0 auto;\n    height: 10px;\n    width: 10px;\n    top:10px;\n}\n\n.footer{\n    position:absolute;\n    left:0;\n    bottom:0;\n    right:0;\n    height: calc(100% - 60px);\n}\n\n.carousel-indicators button {\n    height: 10px !important;\n    width: 10px !important;\n    margin: 0 10px !important;\n    border-radius: 100%;\n    background-color: #FFFFFF !important;\n\n}\n.carousel-item {\n    height: 190px;\n}\n.carousel-indicators {\n    bottom: -50px;\n}\n\n.menuTitle{\n    font-family: \"Helvetica\";\n    font-size: 30px;\n}\n\n.collapsible .content {\n    padding: 6px;\n    background-color: #fffbf2;\n    border-radius:5px;\n}\n.collapsible .header {\n    background-color: #2d2723;\n    padding: 6px;\n    cursor: pointer;\n    border-radius:5px;\n}\n@font-face {\n  font-family: 'Helvetica-NeueLT';\n  src: local('Helvetica-NeueLT'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n}\n\n", ""]);
// Exports
module.exports = exports;
