import constants from "../constants";
import { put, takeEvery, all, call } from 'redux-saga/effects';
import Storage from "../../storage/Storage";
import store from "..";
import {areOptionsEqual} from "../../helpers";

function* addToCart({payload}) {
    try {
        let params = {...payload};
        console.log("value of params in cart", params);
        delete params.onFinished;
        
        yield put({ type: constants.CART_SET_LOADING, payload: true });

        let currentCart = store.getState().carts.details;

        const itemIndex = currentCart.findIndex(item => params.menu_item_id === item.menu_item_id);

        if (itemIndex > -1) {
            const sameOrderMenu = currentCart[itemIndex];
            const isSame = areOptionsEqual(payload.extra, sameOrderMenu.extra) && payload.notes === sameOrderMenu.notes;

            if (isSame) {
                sameOrderMenu.qty = parseInt(sameOrderMenu.qty) + payload.qty;
            } else {
                currentCart.push(payload);
            }
        } else {
            currentCart.push(payload);
        }
        Storage.setCart(currentCart);
        
        yield put({ type: constants.CART_SET_DETAILS, payload: currentCart });
        yield put({ type: constants.CART_SET_LOADING, payload: false });
        
        if(typeof payload.onFinished !== 'undefined') {
            payload.onFinished();
        }
    } catch(e) {
        yield put({ type: constants.CART_SET_LOADING, payload: false });
    }
}

function* updateCart({payload}) {
    try {
        yield put({ type: constants.CART_SET_LOADING, payload: true });
        yield call(Storage.setCart, payload);
        yield put({ type: constants.CART_SET_DETAILS, payload: payload });
        yield put({ type: constants.CART_SET_LOADING, payload: false });
    } catch(e) {
        yield put({ type: constants.CART_SET_LOADING, payload: false });
    }
}

function* clearCart() {
    try {
        yield put({ type: constants.CART_SET_LOADING, payload: true });
        Storage.clearCart()
        yield put({ type: constants.CART_SET_DETAILS, payload: [] });
        yield put({ type: constants.CART_SET_LOADING, payload: false });
    } catch(e) {
        yield put({ type: constants.CART_SET_LOADING, payload: false });
    }
}

function* getCartDetails(action) {
    console.log('saga getCartDetails');
    try {
        yield put({ type: constants.CART_SET_LOADING, payload: true });
        let currentCart = yield call(Storage.getCart);
        console.log('saga getCartDetails', currentCart);
        yield put({ type: constants.CART_SET_DETAILS, payload: currentCart });
        yield put({ type: constants.CART_SET_LOADING, payload: false });
    } catch(e) {
        console.log('saga getCartDetails error', e);
        yield put({ type: constants.CART_SET_LOADING, payload: false });
    }
}

export default function* () {
    yield all([
        takeEvery(constants.CART_GET_DETAILS, getCartDetails),
        takeEvery(constants.CART_ADD_TO_CART, addToCart),
        takeEvery(constants.CART_UPDATE_CART, updateCart),
        takeEvery(constants.CART_CLEAR_CART, clearCart),
    ]);
}
